import React, { useEffect, useState } from 'react';
import { getClientContactList, updateRegister } from '../Api/ClientContact';
import { FaCheck, FaBell } from 'react-icons/fa';
import { ClientContactModal } from './ClientContactModal';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getAllEmployees } from '../../admin/Api/AdminServices';
import { IMAGE_DOMAIN } from '../../../config/Constant';

function ClientContactTableAdmin(props) {
  const { user, token } = props;
  const [contactList, setContactList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [employeeFilter, setEmployeeFilter] = useState('');
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await getAllEmployees(token, user);
        setEmployees(response.data);
      } catch (error) {
        console.error('Error getting users:', error);
      }
    };

    fetchEmployees();
  }, [token]);

  useEffect(() => {
    const fetchContactLists = async () => {
      try {
        const employees = await getAllEmployees(token, user);

        for (const employee of employees.data) {
          const data = await getClientContactList(employee.id, token);
          const contactsWithData = data.map(contact => ({
            ...contact,
            employeeName: employee.username,
            employeeImage: employee.profile.image
          }));
          setContactList(prevContactList => prevContactList.concat(contactsWithData));
        }
      } catch (error) {
        console.error('Error getting contact:', error);
      }
    };

    fetchContactLists();
  }, [token]);

  const handleOpenModal = (contact) => {
    setSelectedContact(contact);
    setModalOpen(true);

    if (contact.status === 0) {
      updateContactStatus(contact.id);
    }
  };

  const handleCloseModal = () => {
    setSelectedContact(null);
    setModalOpen(false);
  };

  const updateContactStatus = async (contactId) => {
    try {
      const selectedContact = contactList.find(contact => contact.id === contactId);
      if (!selectedContact) {
        console.error('Contacto not found');
        return;
      }

      const updatedData = {
        status: 1,
        customer_user_id: user,
        name: selectedContact.name
      };

      await updateRegister(contactId, updatedData, token);

      setContactList(prevContactList =>
        prevContactList.map((c) =>
          c.id === contactId ? { ...c, status: 1 } : c
        )
      );
    } catch (error) {
      console.error('Error updating contact:', error);
    }
  };

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    const options = {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    return date.toLocaleDateString('pt-BR', options);
  };

  const getFormattedDateTime = () => {
    const date = new Date();
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    return date.toLocaleDateString('pt-BR', options).replace(/\//g, '-').replace(/,/g, '').replace(/\:/g, '-').replace(/\s/g, '_');
  };

  const exportToExcel = () => {
    const data = contactList.map(contact => ({
      'Usuário': contact.employeeName,
      'Nome': contact.name,
      'País': contact.country_code,
      'Telefone': contact.phone,
      'Email': contact.email,
      'Comente': contact.comment,
      'Data de Criação': formatCreatedAt(contact.created_at),
      'Estado': contact.status === 0 ? 'Não Lido' : 'Lido'
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contatos');
    const filename = `Contatos-SouEu_${getFormattedDateTime()}.xlsx`;
    XLSX.writeFile(workbook, filename);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Usuario', 'Nome', 'País', 'Telefone', 'Email', 'Comente', 'Data de Criação', 'Estado']],
      body: contactList.map(contact => [
        contact.employeeName,
        contact.name,
        contact.country_code,
        contact.phone,
        contact.email,
        contact.comment,
        formatCreatedAt(contact.created_at),
        contact.status === 0 ? 'Não Lido' : 'Lido'
      ]),
      startY: 20,
      styles: {
        fontSize: 10,
        cellPadding: 2
      },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 15 },
        4: { cellWidth: 30 },
        5: { cellWidth: 20 }
      }
    });
    const filename = `Contatos-SouEu_${getFormattedDateTime()}.pdf`;
    doc.save(filename);
  };

  const handleChangeEmployeeFilter = (event) => {
    setEmployeeFilter(event.target.value);
  };

  const filteredContactList = employeeFilter
    ? contactList.filter(contact => contact.employeeName === employeeFilter)
    : contactList;

  let combinedList = [...filteredContactList];

  if (employeeFilter === '') {
    combinedList = contactList;
  }

  combinedList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  return (
    <div>
      <div className='flex justify-between mb-4'>
        <h2 className="font-medium text-lg text-gray-800">Registro de Notificação</h2>
        <div className='flex items-center'>
          <select
            className="bg-neutral-800 text-white py-1 px-2 rounded-md"
            value={employeeFilter}
            onChange={handleChangeEmployeeFilter}
          >
            <option value="">Todos os usuários</option>
            {employees.map(employee => (
              <option key={employee.id} value={employee.username}>{employee.username}</option>
            ))}
          </select>
          <button onClick={exportToExcel} className="bg-green-500 text-white py-1 px-2 text-sm font-semibold rounded-md ml-2">Exportar para Excel</button>
          <button onClick={exportToPDF} className="bg-blue-500 text-white py-1 px-2 text-sm font-semibold rounded-md ml-2">Exportar para PDF</button>
        </div>
      </div>

      {filteredContactList.map(contact => (
        <div
          key={contact.id}
          className="rounded-lg border border-neutral-300 shadow-md bg-neutral-100 p-1 px-2 md:p-4 flex flex-col md:flex-row md:items-center justify-between mb-4 cursor-pointer hover:bg-neutral-200"
          onClick={() => handleOpenModal(contact)}
        >
          <div className="flex items-center">
            <img
              src={`${IMAGE_DOMAIN}${contact.employeeImage}`}
              className="rounded-full h-12 w-12 mr-4"
              alt={contact.employeeName}
              title={contact.employeeName}
            />
            <div>
              <p className="text-sm font-medium mb-1">
                <strong>{contact.name}</strong> Quer entrar em contato
              </p>
              <p className="text-sm text-gray-600">
                {formatCreatedAt(contact.created_at)}
              </p>
            </div>
          </div>
          <div className="flex items-center mt-4 md:mt-0">
            <div className="flex items-center mr-4">
              <span className='text-neutral-800 text-xs font-medium px-2 whitespace-nowrap'>
                {contact.status === 0 ? 'No Leído' : 'Leído'}
              </span>
              {contact.status === 0 ? (
                <div className="flex items-center justify-center bg-blue-500 text-white rounded-full w-6 h-6 mr-2">
                  <FaBell className="text-white"></FaBell>
                </div>
              ) : (
                <div className="flex items-center justify-center bg-green-500 text-white rounded-full w-6 h-6 mr-2">
                  <FaCheck className="text-white"></FaCheck>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}

      <ClientContactModal isOpen={modalOpen} onClose={handleCloseModal} contact={selectedContact} />
    </div>
  );
}

export { ClientContactTableAdmin };
