import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { FaCheck, FaBan, FaEye } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { getAllBookings, useUser, updateBooking, getBookingSettings } from '../../Api/Booking';
import { BookingEdit } from './BookingEdit';
import { ButtonsForm } from './ButtonsForm';

Modal.setAppElement('#root');

const HystoryTable = (props) => {
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);
  const [tableData, setTableData] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [kids, setKids]=useState("");
  const [teen, setTeen]=useState("");
  const [userEmail, setUserEmail]=useState("");
  const [time_bet_booking, setTimeBetBooking]=useState("");
  const [filterToday, setFilterToday] = useState(false);
  const user = useUser(token);
  const expandedRowRef = useRef(null);
  const {id}=props;

  const handleDetailsClick = (booking) => {
    setExpandedRow(expandedRow === booking.id ? null : booking.id);
    setSelectedBooking(booking);
  };

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationAction, setConfirmationAction] = useState(null);

  const openConfirmationModal = (message, action) => {
    setConfirmationMessage(message);
    setConfirmationAction(() => action);
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setConfirmationMessage('');
    setConfirmationAction(null);
  };

  const confirmAction = () => {
    if (confirmationAction) {
      confirmationAction();
    }
    closeConfirmationModal();
  };

  const filterBookingsForToday = (bookings) => {
    const today = new Date().toISOString().split('T')[0];
    return bookings.filter((booking) => {
      const bookingDate = new Date(booking.date);
      const bookingDateISOString = bookingDate.toISOString().split('T')[0];
      return bookingDateISOString === today;
    });
  };
  
  const loadBookings = async () => {
    try {
      if (user && id) {
        const settingsResponse = await getBookingSettings(id, token);
        const kids = settingsResponse.data.kids;
        const teen = settingsResponse.data.teen;
        const userEmail = settingsResponse.data.email;
        const time_bet_booking = settingsResponse.data.time_bet_booking;
  
        const res = await getAllBookings(id, token);
  
        setKids(kids);
        setTeen(teen);
        setUserEmail(userEmail);
        setTimeBetBooking(time_bet_booking);
  
        if (res.data && Array.isArray(res.data)) {
          let filteredBookings = res.data;
        
          // Aplicar el filtro para el día de hoy si está habilitado
          if (filterToday) {
            const today = new Date();
            
            // Ajustar la zona horaria a -4
            today.setHours(today.getHours() - 4);
            
            const todayISOString = today.toISOString().split('T')[0];
            
            filteredBookings = filterBookingsForToday(filteredBookings, todayISOString);
          }
        
          const sortedBookings = res.data.sort((a, b) => new Date(a.date) - new Date(b.date));
        
          const bookingsWithDateTime = sortedBookings.map((booking) => {
            const [date, time] = booking.date.split('T');
            return {
              ...booking,
              date: date,
              time: time,
            };
          });
        
          setTableData(bookingsWithDateTime);
        } else {
          console.error('Invalid data format received:', res.data);
        }
      } else {
        //console.error('El usuario aún no está cargado o no tiene un ID definido');
      }
    } catch (error) {
      console.error('Error loading booking:', error.message);
    }
  };
  
  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };
  
  useEffect(() => {
    // Utilizar la función loadBookings
    loadBookings();

    if (expandedRowRef.current) {
      expandedRowRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [token, expandedRow, user,filterToday]);

  const updateStatusBooking = async (bookingId, newStatus) => {
    try {
      const bookingToUpdate = tableData.find((booking) => booking.id === bookingId);
  
      if (bookingToUpdate && (newStatus === 1 || newStatus === 2)) {
 
          await updateBooking(bookingId, { status_booking: newStatus }, token);
  
          loadBookings();
      }
    } catch (error) {
      console.error('Error booking status:', error.message);
    }
  };

  const handleUpdateBooking = async (bookingId, updatedData) => {
    try {
    
      await updateBooking(bookingId, updatedData, token);
      loadBookings();
    } catch (error) {
      console.error('Error updating booking:', error.message);
    }
  };

  const isBookingForToday = (booking) => {
    const today = new Date().toISOString().split('T')[0];
    const bookingDate = new Date(booking.date);
    const bookingDateISOString = bookingDate.toISOString().split('T')[0];
    return bookingDateISOString === today;
  };

  
  
  return (
    <div className="container mx-auto mt-2 p-6 bg-white rounded-md shadow-md">
      <div className="px-8 flex justify-between mb-6">
      <h2 className="font-medium text-lg min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
      Reservas
      </h2>
      <button
          id="filter-today-button"
          className={`px-3 py-1 rounded text-white text-xs overflow-hidden font-bold ${
            filterToday ? 'bg-emerald-500' : 'bg-neutral-800'
          }`}
          onClick={() => {
            setFilterToday(!filterToday);
          }}
        >
          {filterToday ? 'Mostrar tudo': 'Mostrar hoje'}
        </button>
    </div>

    {tableData.length === 0 ? (
        <p className="text-center text-gray-500">Não há reservas disponíveis.</p>
      ) : (
        <div className="table-container overflow-x-auto">
          <table className="min-w-full bg-white border border-neutral-800 text-center">
        <thead className="bg-neutral-800 text-white text-sm">
          <tr>
            <th className="py-2 px-4">Nome</th>
            <th className="py-2 px-4">Data</th>
           {/*  <th className="py-2 px-4">Hora</th> */}

           {/*  <th className="py-2 px-4">Teléfono</th> */}
            <th className="py-2 px-4">Estado</th>
            <th className="py-2 px-4">Ações</th>            
          </tr>
        </thead>
        <tbody>
          {tableData
          .filter((booking) => {
            // Verificar el estado de la reserva y si se debe mostrar para el día actual
            return (
              booking.status_booking !== 0 &&
              booking.status_booking !== 1 &&
              booking.status_booking !== 3 &&
              booking.status_booking !== 5 &&
              (!filterToday || isBookingForToday(booking))
            );
          })
          .map((booking) => (
            <React.Fragment key={booking.id}>

            <tr key={booking.id} className="bg-white border-t border-gray-300">
             
              <td className="py-2 px-4 flex flex-col items-center">
                <p className="mt-1">{booking.nombre}</p>
              </td>

              <td className="py-2 px-4">
                <p>{booking.date}</p>
                <strong>{formatTime(booking.time)}</strong>
              </td>
        
              <td className="py-2 px-4">
                    <button 
                    id='estado' 
                    className={`
                      px-3 py-1 rounded text-white text-xs overflow-hidden font-semibold
                      ${booking.status_booking === 0 ? 'bg-cyan-500' : ''}
                      ${booking.status_booking === 1 ? 'bg-emerald-500' : ''}
                      ${booking.status_booking === 2 ? 'bg-red-500' : ''}
                      ${booking.status_booking === 3 ? 'bg-orange-500' : ''}
                      ${booking.status_booking === 4 ? 'bg-purple-500' : ''}
                    `}
                    style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  >
                    {booking.status_booking === 0 ? 'Em Espera' : ''}
                    {booking.status_booking === 1 ? 'Aceptado' : ''}
                    {booking.status_booking === 2 ? 'Cancelado' : ''}
                    {booking.status_booking === 3 ? 'Em Curso' : ''}
                    {booking.status_booking === 4 ? 'Terminado' : ''}
                  </button>
              </td>

              <td className="py-2 px-4 flex items-center justify-center space-x-1">
                <button
                  id={`details-${booking.id}`}
                  className="flex items-center px-2 py-1 bg-blue-500 text-white text-xs rounded hover:bg-blue-600"
                  onClick={() => handleDetailsClick(booking)}>
                  <FaEye className="cursor-pointer text-white-500 text-sm mr-1" />
                  Ver
                </button>
                {booking.status_booking === 0 && (
                  <>
                    <button 
                      id='aceptar' 
                      className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                      onClick={() => openConfirmationModal(`¿Aceptando reserva, está seguro?`, () => updateStatusBooking(booking.id, 1))}>
                      <FaCheck className="cursor-pointer text-white-500 text-sm" />
                    </button>
                    <button 
                      id='cancelar' 
                      className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                      onClick={() => openConfirmationModal(`¿Cancelando reserva, está seguro?`, () => updateStatusBooking(booking.id, 2))}>
                      <FaBan className="cursor-pointer text-white-500 text-sm" />
                    </button>
                  </>
                )}
              </td>

            </tr>
            {expandedRow === booking.id && (
                <tr ref={expandedRowRef}>
                  <td colSpan="9" className="bg-gray-200 p-4 justify-center">
                    <BookingEdit 
                      booking={selectedBooking} 
                      onUpdateBooking={handleUpdateBooking}
                      kids={kids}
                      teen={teen}
                      userEmail={userEmail}
                      formattedTime={formatTime(booking.time)} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
          </table>
        </div>
      )}

      <Modal
        isOpen={isConfirmationModalOpen}
        onRequestClose={closeConfirmationModal}
        className="modal-container"
        overlayClassName="modal-overlay bg-black bg-opacity-50"
        contentLabel="Confirmation Modal"
      >
        <div className="modal-content bg-white shadow-md p-6 rounded-lg border-2 border-neutral-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <p className="text-base mb-4">{confirmationMessage}</p>
          <div className="flex space-x-4 justify-center text-center text-sm">
            <button
              className="bg-green-500 text-white px-3 py-1 rounded-lg hover:bg-green-600"
              onClick={confirmAction}
            >
              Aceitar
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600"
              onClick={closeConfirmationModal}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
      {isConfirmationModalOpen && (
        <div className="fixed inset-0 bg-black opacity-50" onClick={closeConfirmationModal}></div>
      )}
    </div>
  );
};

export { HystoryTable };
