import React from "react";

import { IMAGE_DOMAIN } from "../../config/Constant";
import { invertColor } from "../../utils/invertColor";
import { overrideUndefinedImage } from "../../utils/overrideUndefinedImage";
import { FaStore, FaStar } from 'react-icons/fa';

import QRcode from "qrcode.react";

// eslint-disable-next-line no-unused-vars
import "../../styles/previewProfile.css";
import { orange } from "@mui/material/colors";

const PreviewDigitalCard = (props) => {
  const { profileData, userData, customSocialMedia } = props;
  const userColor = profileData.color;
  const textColor = invertColor(userColor, true);

  const hasContactType = customSocialMedia.some(link => link.type === "contact");
  const hasStoreType = customSocialMedia.some(link => link.type === "store");
  const hasMainLinkType = customSocialMedia.some(link => link.type === "mainLink");


  return (
    <div className="rounded-3xl w-full h-[32rem] px-[0.35rem] pt-8 pb-2 bg-neutral-300">
      <div className="relative bg-[rgb(245,245,245)] rounded-b-3xl h-full overflow-y-auto">
        <div
          className="h-[30%] shadow-background"
          style={{
            backgroundImage: `linear-gradient(135deg, ${userColor}, 40%, ${calculateGradientColor(userColor) || userColor
            })`,
        }}
        >
          <img
            className="h-full mx-auto"
            alt="wallpaper"
            src={`${IMAGE_DOMAIN}${profileData.background}`}
          />
        </div>
        <div className="-mt-7 flex rounded-full w-fit mx-auto shadow-avatar">
          <img
            className="h-16 rounded-full p-[2px] bg-white"
            alt="avatar"
            src={`${IMAGE_DOMAIN}${profileData.image}`}
          />
        </div>
        <div>
          <div className="mt-[0.3rem] flex flex-col px-2 gap-[3px] font-montserrat text-center">
            <p className="text-sm font-medium"> {profileData.public_name} </p>
            <p className="text-[11px]"> {profileData.career} </p>
            <p className="text-[11px]"> {profileData.description} </p>
          </div>
          <div className="mt-1 w-full flex justify-center items-center">
            <button
              style={{ backgroundColor: userColor, color: textColor }}
              className="text-[6px] px-2 py-0.5  shadow font-semibold mr-1 rounded-md"
            >
              Salvar contato
            </button>
            {hasContactType && (
              <button
                className="text-[6px] px-2 py-0.5 bg-violet-500 text-white shadow mr-1 font-semibold rounded-md"
              >
                Enviar dados
              </button>
            )}
            {userData.is_booking && (
              <button
                className="text-[6px] px-2 py-0.5 bg-orange-500 text-white shadow font-semibold rounded-md"
              >
                Criar reserva
              </button>
            )}

          </div>
          <div className="mt-1 w-full flex flex-col justify-center items-center">
            {hasStoreType && userData.is_ecommerce && (
              <button
                style={{ backgroundColor: '#1c1c1c', color: textColor }}
                className="flex justify-center items-center text-[6px] px-2 py-0.5 shadow font-semibold mr-1 rounded-md min-w-[190px] mb-1"
              >
                <FaStore className="mr-1" />
                Visitar My Store
              </button>
            )}

            {hasMainLinkType && (
              <button
                style={{ backgroundColor: userColor, color: textColor }}
                className="flex justify-center items-center text-[6px] px-2 py-0.5 shadow font-semibold mr-1 rounded-md min-w-[190px]"
              >
                <FaStar className="mr-1" />
                Enlace Principal
              </button>
            )}
          </div>
          <div className="mx-2 mt-2 py-2 text-center rounded-md bg-white">
            <p className="text-[13px] mb-1"> Redes de Contato </p>

            <div className="gap-x-3 gap-y-1 px-2 w-full grid grid-cols-3">
              {customSocialMedia.map((link, index) => (
              // Verificar si el tipo es "store" y si userData.is_ecommerce es false
              !(link.type === "store" && !userData.is_ecommerce) && (
                <div
                  className="w-full py-1 overflow-hidden overflow-ellipsis rounded-md transition-colors cursor-pointer duration-300 hover:bg-stone-200"
                  key={index + 999}
                >
                  <img
                    className="max-w-[2rem] h-8 aspect-square object-cover rounded mx-auto"
                    alt={`icono de ${link.title}`}
                    src={overrideUndefinedImage(
                      link.type,
                      `${IMAGE_DOMAIN}${link.image}`
                    )}
                  />
                  <p className="text-[9px] px-1 mt-1"> {link.title} </p>
                </div>
              )
              ))}
            </div>
          </div>

          <QRcode
            className="mt-4 mx-auto"
            size={96}
            bgColor="#F5F5F5"
            value={
              userData ? `https://www.soueu.com.br/u/${userData?.public_id}` : ""
            }
          />
        </div>

        <div className="h-4"></div>
      </div>
    </div>
  );
};

function calculateGradientColor(hex) {
  if (!hex) return "";
  const rgb = hexToRgb(hex);
  const hsl = rgbToHSL(rgb.r, rgb.g, rgb.b);
  let light = hsl.l;
  if (light > 25) {
    light -= 25;
  } else {
    light += 25;
  }

  return `hsl(${hsl.h}, ${hsl.s}%, ${light}%)`;
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
}

function rgbToHSL(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s
    ? l === r
      ? (g - b) / s
      : l === g
      ? 2 + (b - r) / s
      : 4 + (r - g) / s
    : 0;
  return {
    h: 60 * h < 0 ? 60 * h + 360 : 60 * h,
    s: 100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    l: (100 * (2 * l - s)) / 2,
  };
}

export default PreviewDigitalCard;
