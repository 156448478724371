import React, { useState } from "react";

import { useSelector } from "react-redux";

import whatsappIcon from "../../../../assets/images/social_media_icons/icons8-whatsapp-96.png";
import { useForm } from "react-hook-form";

import { removeCountryCode } from "../../../../utils/removeCountryCode";
import LoadingButton from "../../../../components/LoadingButton";

import CountryCodeOptions from "../../../../utils/CountryCodeOptions";
import { objectToLinkByType } from "../../../../utils/objectToLinkByType";


const AddWhatsappModal = (props) => {
  const token = useSelector((state) => state.login.token);

  const { setShowModal, link } = props;

  const titleType = "de Whatsapp";
  const imageSource = whatsappIcon;

  const { justPhone, countryCode } = removeCountryCode(
    link?.phone_number ?? ""
  );

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      ...link,
      phone_number: justPhone,
      country_code: countryCode.length ? countryCode : "55",
    },
  });

  const { onComplete, onSubmit } = props;

  if (!onComplete) {
    console.log("Se debe pasar parámetro 'onComplete'");
  }
  if (!onSubmit) {
    console.log("Se debe pasar parámetro 'onSubmit'");
  }

  function closeModal() {
    setShowModal(false);
  }

  function formHasBeenEdited() {
    return formState.isDirty;
  }

  async function handleAddCustomSocialMedia(data) {
    const phoneNumber = data.country_code + data.phone_number;
    data.phone_number = phoneNumber;
  
    data = objectToLinkByType(data, "whatsapp");
    setIsLoading(true);
    const responsePromise = onSubmit(data, token);

    responsePromise
      .then((response) => {
        onComplete(response.data);
        closeModal();
      })
      .catch((error) => {
        console.error(error);
        window.alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <p className="font-medium"> {`Agregar enlace ${titleType}`} </p>
      <div className="mt-4 flex items-center">
        <div className="filter hover:contrast-[0.9]">
          <img
            className="max-w-[4rem] rounded-md cursor-pointer"
            alt={`Icono ${titleType}`}
            src={imageSource}
          />
        </div>
      </div>
      <form
        className="mt-4 flex flex-col text-sm"
        action="POST"
        onSubmit={handleSubmit(handleAddCustomSocialMedia)}
      >
        <label
          className="mt-0 text-stone-800 font-medium"
          htmlFor="phone_number"
        >
          Telefone
        </label>
        <div className="flex mt-1">
          <select {...register("country_code")} className="px-2 w-fit">
            <CountryCodeOptions />
          </select>
          <input
            className="px-4 py-2 border outline-none placeholder:text-stone-500"
            type="tel"
            id="phone_number"
            {...register("phone_number")}
            placeholder="71234567"
            required={true}
          />
        </div>

        <label className="mt-4 text-stone-800 font-medium" htmlFor="message">
          Mensaje
        </label>
        <input
          className="mt-1 px-4 py-2 border outline-none placeholder:text-stone-500"
          type="text"
          id="message"
          {...register("message")}
          placeholder="Hola! Contáctame"
          required={false}
        />
        <div className="mt-4 flex items-center justify-end">
          <div className="flex sm:flex-row gap-2">
            <button
              type="button"
              onClick={closeModal}
              className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
            >
              Cancelar
            </button>

            <LoadingButton
              type="submit"
              isLoading={isLoading}
              className="px-6 py-1 font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
              disabled={!formHasBeenEdited()}
            >
              Agregar
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};
export default AddWhatsappModal;
