import { useState, useEffect } from "react";

export function FrequentlyAsked(props) {
  const { categories } = props;
  const titles = categories.map((a) => a.title);
  const elements = categories.map((a) => a.element);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Cambiar automáticamente a la siguiente categoría cada 5 segundos
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % categories.length);
    }, 15000);

    return () => clearInterval(intervalId);
  }, [categories]);

  function handleChangeTab(index) {
    return () => {
      setIndex(index);
    };
  }

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="mt-0 lg:mt-12 mr-8">
        <h2 className="px-4 text-2xl md:text-3xl lg:text-4xl font-semibold text-center">
        Perguntas frequentes
        </h2>

        <div className="mt-8 lg:mt-16 flex flex-col items-center">
          <div className="flex flex-col items-center gap-4">
            {titles.map((title, i) => (
              <button
                key={i}
                onClick={handleChangeTab(i)}
                className={`
              ${
                index === i ? "border-b-stone-700 text-stone-950" : "text-stone-700"
              }
              px-4 border-b-2 w-fit text-xl transition-colors font-medium border border-transparent`}
              >
                {title}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-8 px-8 flex flex-col gap-4">{elements[index]}</div>
    </div>
  );
}
