import { useState } from "react";
import { FaTimes } from 'react-icons/fa';
import { useSelector } from "react-redux";
import { DOMAIN } from "../../../config/Constant";
import { toast } from 'react-toastify';

function SimpleEditEmailModal({ isOpen, onClose, userEmail, userId }) {
  const token = useSelector((state) => state.login.token);
  const [email, setEmail] = useState(userEmail);
  const [emailError, setEmailError] = useState(false);
  const [confirmChange, setConfirmChange] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const updateUserEmail = async () => {
    if (!validateEmail()) {
      setEmailError(true); 
      return;
    }
    if (email === userEmail) {
      return;
    }

    if (!confirmChange) {
      setConfirmChange(true);
      return;
    }

    try {
      const response = await fetch(`${DOMAIN}auth/user?user_id=${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      toast.success("Correo modificado!", {
        position: "top-center",
        bodyClassName: "text-sm",
        autoClose: 1500,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });
      onClose();
    } catch (error) {
      console.error("Error updating user email:", error);
    }
  };

  const handleConfirmChange = () => {
    setConfirmChange(false);
    updateUserEmail();
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-neutral-800 bg-opacity-10">
          <div className="bg-white max-w-md p-6 rounded-md shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <p className="text-sm font-semibold">Cambiar Correo Electrónico</p>
              <FaTimes onClick={onClose} className="text-neutral-800 cursor-pointer w-4 h-4" />
            </div>
            <div className="text-gray-800 flex items-center">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Ingrese su correo electrónico"
                className="border border-gray-300 rounded px-3 py-2 w-full"
              />
              <button
                onClick={updateUserEmail}
                className={`ml-4 flex items-center text-sm font-semibold px-4 py-2 rounded-md ${emailError ? 'bg-neutral-400 text-white cursor-not-allowed' : 'bg-neutral-800 text-white hover:bg-neutral-700'}`}
                disabled={emailError}
              >
                Cambiar
              </button>
            </div>
            {emailError && <span className="text-red-500">Ingrese un correo electrónico válido.</span>}
          </div>
        </div>
      )}
      {confirmChange && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-neutral-800 bg-opacity-10">
          <div className="bg-white max-w-md p-6 rounded-md shadow-lg">
            <p className="text-gray-800 mb-4">¿Está seguro de que desea cambiar el correo electrónico de <strong>{userEmail}</strong> a <strong>{email}</strong>?</p>
            <div className="flex justify-end text-xs font-semibold">
              <button
                onClick={handleConfirmChange}
                className="px-4 py-2 rounded-md bg-neutral-800 text-white hover:bg-neutral-700 mr-2"
              >
                Confirmar
              </button>
              <button
                onClick={() => setConfirmChange(false)}
                className="px-4 py-2 rounded-md bg-red-600 text-white hover:bg-red-700"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export { SimpleEditEmailModal };
