import React from 'react';
import {FaWhatsapp, FaEnvelope} from 'react-icons/fa'

function ClientContactModal({ isOpen, onClose, contact, ids }) {
  if (!isOpen || !contact) return null;

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    const options = {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    return date.toLocaleDateString('pt-BR', options);
  };
  
  const handleWhatsappClick = () => {
    const phoneNumber = `${contact.country_code}${contact.phone}`;
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  };
  const handleEmailClick = () => {
    const emailSubject = encodeURIComponent("Consulta sobre el cliente");
    const emailBody = encodeURIComponent("Hola,\n\nMe gustaría consultar acerca del cliente: " + contact.name);
    const emailUrl = `mailto:${contact.email}?subject=${emailSubject}&body=${emailBody}`;
    window.location.href = emailUrl;
  };

  return (
    <div className={`fixed inset-0 flex z-20 items-center justify-center ${isOpen ? '' : 'hidden'}`}>
  <div className="absolute inset-0 bg-neutral-900 opacity-50"></div>
  <div className="bg-white p-8 rounded-md  z-10">
    <div className="mb-4">
        <div className="flex items-center mb-4">
            <p className="text-gray-600 mr-2">Nome do Cliente:</p>
            <p className="font-semibold">{contact.name}</p>
        </div>
        <div className="flex mb-4 justify-center">
            {contact.phone && (
              <div
                className="flex items-center mr-4 bg-green-500 py-2 px-2 rounded-lg cursor-pointer"
                onClick={handleWhatsappClick}>
                <FaWhatsapp className="mr-2 text-white text-3xl" />
                <p className="font-medium text-white rounded-md p-1">{contact.country_code} {contact.phone}</p>
              </div>
            )}
            {contact.email && (
              <div
                className="flex items-center bg-blue-500 py-2 px-2 rounded-lg cursor-pointer"
                onClick={handleEmailClick}>
                <FaEnvelope className="mr-2 text-white text-xl" />
                <p className="font-medium text-white rounded-md p-1">{contact.email}</p>
              </div>
            )}
        </div>

        {(contact.comment &&
            <div className="mb-4 flex">
                <label htmlFor="comment" className="text-gray-600 mr-2">Comente:</label>
                <textarea id="comment" className="border bg-gray-100 p-2 rounded-md flex-1" readOnly value={contact.comment}></textarea>
            </div>
        )}
    </div>
    <div className=" flex justify-between items-center">
      <div className="text-xs">
        <p className="text-gray-600"> {formatCreatedAt(contact.created_at)}</p>
        <p className="text-gray-600"> {contact.status === 0 ? 'Não Lido' : 'Lido'}</p>
      </div>
      <button onClick={onClose} className="bg-black text-white py-2 px-4 rounded-lg">Fechar</button>
    </div>
  </div>
</div>

  );
}

export { ClientContactModal };
