import axios from 'axios';
import { DOMAIN, LOCALHOST_DOMAIN } from "../../../config/Constant";

// Crear un nuevo descuento
export const createNewProduct = async (formData, token) => {
  try {
    const response = await axios.post(`${DOMAIN}pay/productos/`, formData, {
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'multipart/form-data', // Asegúrate de especificar 'multipart/form-data' para FormData
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Obtener información de un producto por ID
export const getProductById = (token, productId) => {
  return axios.get(`${DOMAIN}pay/productos/${productId}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
};

// Actualizar un producto por ID
export const updateProductById = async (token, productId, formData) => {
  try {
    const response = await axios.put(`${DOMAIN}pay/productos/${productId}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `JWT ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Obtener todos los productos
export const getAllProducts = (token) => {
  return axios.get(`${DOMAIN}pay/productos/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
};

// Eliminar un producto por ID
export const deleteProductById = (token, productId) => {
  return axios.delete(`${DOMAIN}pay/productos/${productId}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
};


export const getProducts = (token) => {
  return axios.get(`${DOMAIN}pay/productos/list`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
};