/* import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDbkLm-AW1Ai6Qd8IoCG59_BtMPoO3NxfE",
  authDomain: "soyyo-auth.firebaseapp.com",
  projectId: "soyyo-auth",
  storageBucket: "__BUCKET__",
  messagingSenderId: "___sender_id__",
  appId: "__appid__",
  measurementId: "optional",
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); */




import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBc9_kqRbPTO6Vypx3IV-l7k5bzWkkfhYE",
  authDomain: "soueudigital-auth.firebaseapp.com",
  projectId: "soueudigital-auth",
  storageBucket: "soueudigital-auth.appspot.com",
  messagingSenderId: "1039278449353",
  appId: "1:1039278449353:web:bef286ec32e449fbc7a318",
  measurementId: "G-E3V2C07B34"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);