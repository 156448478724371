import React, { useState } from "react";
import CardContainer from "../../components/CardContainer";
import { ClientContactTableAdmin } from './Components/ClientContactTableAdmin';
import { ClientContactTable } from './Components/ClientContactTable';
import { useSelector } from 'react-redux';

export function ClientContactPage(props) {
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);

  const { user, userPersonified } = props;

  let id = user.id;
  let is_admin = user.is_admin;

  if (userPersonified) {
    id = userPersonified.id;
    is_admin = userPersonified.is_admin;
  }

  return (
    <div className="pt-8 w-full flex flex-col font-montserrat">
      <div className="px-8 flex justify-between items-center mb-4">
        <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
          Notificações
        </h2>
      </div>
      <div className="mt-4 px-6 gap-8 grid grid-cols-1">
        <CardContainer className="px-6 py-4 shadow-md">
          {is_admin ? (
            <ClientContactTableAdmin user={id} token={token} />
          ) : (
            <ClientContactTable user={id} token={token} />
          )}
        </CardContainer>
      </div>
    </div>
  );
}



