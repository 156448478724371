import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import LoadingButton from "../../../../components/LoadingButton";
import infoIcon from "../../../../assets/images/social_media_icons/icons8-store-96.png";
import { createButton } from '../../../Ecommerce/Api/ecommerce';
import EditColorToolbar from '../../../home/EditCardComponents/EditColorToolbarModal';

const AddStoreModal = (props) => {
  const token = useSelector((state) => state.login.token);
  const { setShowModal, userId } = props;
  const titleType = "a: My Store";
  const imageSource = infoIcon;
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState } = useForm();
  const { onComplete, onSubmit } = props;
  const [buttonColor, setButtonColor]= useState("#000000");
  const [formDirty, setFormDirty]=useState(false);

  function closeModal() {
    setShowModal(false);
  }

  function formHasBeenEdited() {
    return formState.isDirty || formDirty; 
  }

  async function handleAddCustomSocialMedia(data) {
    const originalData = {
      title: "Store",
      url: data.url, 
      button_title: data.button_title,
      color: buttonColor,
      is_visible: true,
      type: "store",
      customer_user: userId,
      ...data,
    };

    setIsLoading(true);

    let modifiedData;

    try {
      const customSocialMediaResponse = await onSubmit(originalData, token);
      onComplete(customSocialMediaResponse.data);
      modifiedData = {
        url: originalData.url,
        customer_user: userId,
        button_title: originalData.button_title, 
        color: originalData.color,
      };

      // Aquí llamas a createButton después de crear el social media
      await createButton({
        customer_user: userId,
        button_title: data.button_title,
        color: buttonColor,
        url: data.url,
        enabled: true,
      }, token);

    } catch (error) {
      console.error("Error al agregar custom social media o crear configuración de contacto:", error);
      if (
        (error.response && error.response.status === 400 && error.response.data.detail === "Valor duplicado") ||
        (error.response && error.response.status === 400 && error.response.data.customer_user && error.response.data.customer_user[0] === "Este campo debe ser único.")
      ) {
        try {
        } catch (updateError) {
          console.error("Error al actualizar la configuración de contacto:", updateError);
        }
      } else {
        console.error("Error al agregar custom social media o crear configuración de contacto");
      }
    } finally {
      setIsLoading(false);
      closeModal();
    }
  }

  return (
    <>
      <p className="font-medium"> {`Enlace ${titleType}`} </p>
      <div className="mt-4 flex items-center">
        <img
          className="max-w-[4rem] rounded-md cursor-pointer"
          alt={`Icono ${titleType}`}
          src={imageSource}
        />
        <div className="ml-4">
          <label className="block font-medium">
            Título:
          </label>
          <p className="block w-full px-3 py-2 text-lg font-bold">
            Store
          </p>
        </div>
      </div>
      <form
        className="mt-4 flex flex-col text-sm"
        action="POST"
        onSubmit={handleSubmit(handleAddCustomSocialMedia)}
      >
        <div>
          <label htmlFor="url" className="block font-medium">
            Enlace de My Store
          </label>
          <input
            id="url"
            name="url"
            placeholder="https://tienda.soyyo.digital/sellers/seller_details/"
            className="block w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            {...register("url")}
          />
        </div>

        <div className="mt-4 mb-2">
          <label htmlFor="button_title" className="block font-medium">
            Botão
          </label>
          <input
            id="button_title"
            name="button_title"
            maxLength="45"
            className="block w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            {...register("button_title")}
          />
        </div>

        <EditColorToolbar setButtonColor={setButtonColor} formDirty={formDirty} setFormDirty={setFormDirty}/>

        <div className="mt-4 flex items-center justify-end">
          <div className="flex sm:flex-row gap-2">
            <button
              type="button"
              onClick={closeModal}
              className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
            >
              Cancelar
            </button>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              className="px-6 py-1 font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
              disabled={!formHasBeenEdited()}
            >
              Adicionar
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddStoreModal;
