import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import LoadingButton from "../../../../components/LoadingButton";
import UndefinedImage from "../../../../assets/images/social_media_icons/icon-undefined-background.jpg";
import PlaceholderImage from "../../../../assets/images/social_media_icons/icons8-carpeta-96.png";
import doc from "../../../../assets/images/social_media_icons/doc.png";
import pdf from "../../../../assets/images/social_media_icons/pdf.png";
import ppt from "../../../../assets/images/social_media_icons/ppt.png";
import { uploadFile } from "../../../../utils/uploadImages";

const AddFileModal = (props) => {
  const token = useSelector((state) => state.login.token);

  const { setShowModal } = props;
  const { title } = props;

  const titleType = title ?? "Adicionar ficheiro";
  const fileRefIcon = useRef(null);
  const fileRefContent = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [iconSource, setIconSource] = useState(PlaceholderImage);
  const [fileSource, setFileSource] = useState(UndefinedImage);
  const [fileFormData, setFileFormData] = useState(null);
  const [fileName, setFileName] = useState("");

  const { register, handleSubmit } = useForm();

  const { onSubmit, onComplete } = props;

  function closeModal() {
    setShowModal(false);
  }

  async function handleAddFileLink(data) {
    console.log("data:", data);
    setIsLoading(true);
    data.type = "file";
    data.url = `https://api.soueu.com.br/media/files/${fileFormData.name}`;
    data.file = fileFormData;

    console.log("valor de file form data: ",fileFormData)

    // Enviar al servidor
    onSubmit(data, token)
      .then((responseFile) => {
        onComplete(responseFile.data);
        closeModal();
      })
      .catch((error) => {
        window.alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleOpenFilePicker(fileRef) {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }

  function handleIconUpload(event) {
    event.preventDefault();
    const files = event.target.files;
    const file = files[0];
    setIconSource(URL.createObjectURL(file));
  }

  async function handleFileUpload(event) {
    event.preventDefault();
    const files = event.target.files;
    const file = files[0];
    setFileSource(URL.createObjectURL(file));
    setFileFormData(file);
    setFileName(file.name); // Set the file name

    // Upload the file to the server
    try {
      await uploadFile(files, (reader) => {
        // Handle any success response or other logic here if needed
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }

  // Function to get the icon source based on file type
  function getFileIcon(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return pdf;
      case 'doc':
      case 'docx':
        return doc;
      case 'ppt':
      case 'pptx':
        return ppt;
      default:
        return UndefinedImage;
    }
  }

  return (
    <>
      <p className="font-medium"> {titleType} </p>
      <input
        ref={fileRefIcon}
        onChange={handleIconUpload}
        className="hidden"
        accept="image/x-png,image/jpeg"
        type="file"
      />
      <input
        ref={fileRefContent}
        onChange={handleFileUpload}
        className="hidden"
        accept=".pdf,.doc,.docx,.ppt,.pptx"
        type="file"
      />
      <form
        className="mt-4 flex flex-col text-sm"
        action="POST"
        onSubmit={handleSubmit(handleAddFileLink)}
      >
        <div className="flex items-center">
          <div
            className="filter hover:contrast-[0.9]">
            <img
              className="max-w-[4rem] h-16 aspect-square object-cover rounded-md "
              src={iconSource}
            />
        
          </div>
        </div>
        <label className="font-medium text-stone-800" htmlFor="title">
          Título
        </label>
        <input
          className="mt-1 px-4 py-2 border outline-none placeholder:text-stone-500"
          type="text"
          id="title"
          {...register("title")}
          placeholder="Título"
          required={true}
        />
        <label className="mt-4 text-stone-800 font-medium" htmlFor="file">
          Ficheiro
        </label>
        <div className="mt-1 flex items-center">
          <div
            className="filter hover:contrast-[0.9]"
            onClick={() => handleOpenFilePicker(fileRefContent)}
          >
            <div className="flex items-center cursor-pointer">
              <img
                className="max-w-[4rem] h-16 aspect-square object-cover rounded-md"
                alt={`Añadir ficheiro`}
                src={getFileIcon(fileName)}
              />
              {fileName && (
                <span className="ml-2 text-sm text-stone-800">{fileName}</span>
              )}
            </div>
          </div>
        </div>
        <div className="mt-4 flex items-center justify-end">
          <div className="flex sm:flex-row gap-2">
            <button
              type="button"
              onClick={closeModal}
              className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
            >
              Cancelar
            </button>
            <LoadingButton
              type="submit"
              disabled={!fileFormData}
              isLoading={isLoading}
              className="px-6 py-1 font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
            >
              Adicionar
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddFileModal;
