import React from "react";
import CardContainer from "../../components/CardContainer";
import  { DiscountList }  from "./Components/DiscountTable";
import "./Components/adminComponentStyles.css";



export function Discounts() {

  return (
    <div>

      <div className="Discount">
        <div className="pt-8 w-full flex flex-col font-montserrat">
          <div className="px-8 flex justify-between">
            <h2 className="font-medium text-xl min-w-0 whitespace-nowrap  text-ellipsis">
             Cupons de Desconto
             </h2>
            
            </div>
            <div className="px-8 flex justify-between">  
          </div>
            <div className="mt-4 px-8 w-full">
              
            </div>
            <div>
                <DiscountList />
            </div>
          </div>
        </div>
      </div>
    );
  }
  