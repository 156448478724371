import axios from 'axios'
import { RIA_CHAT_DOMAIN } from '../../../config/Constant';

const CHAT_API_KEY = 'ckYG666ytBydgIhyOzPXr8IcQLUcrGG1ZfKRIJHh';
const CHAT_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2NoYXQucmlhLnZlbnR1cmVzL2FwaS9sb2dpbiIsImlhdCI6MTcxNTcwMDk2OSwibmJmIjoxNzE1NzAwOTY5LCJqdGkiOiIybUNpaGlvRW1vcUd6cEpYIiwic3ViIjoiMiIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.JYxR7udVwWVnNw2_Dvps3OqcWxk4pUsJ_v0SpFND1ac';

const headers = {
  'Authorization': `Bearer ${CHAT_TOKEN}`,
  'apikey': CHAT_API_KEY,
};

export const login = async (data) => {
  const response = await axios.post(`${RIA_CHAT_DOMAIN}login`, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const whatsappTemplate = async () => {
  const response = await axios.get(`${RIA_CHAT_DOMAIN}whatsapp-template`, {
    headers,
  });
  return response.json();
};



export const whatsappContactoList = async () => {
  try {
    const response = await axios.get(`${RIA_CHAT_DOMAIN}whatsapp-contacto-list`, {
      headers: headers
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching WhatsApp Contact List:', error);
    throw error;
  }
};

export const whatsappContacto = async () => {
  try {
    const response = await axios.get(`${RIA_CHAT_DOMAIN}whatsapp-contacto`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching WhatsApp Segment List:', error);
    throw error;
  }
};

export const whatsappSegment = async () => {
  try {
    const response = await axios.get(`${RIA_CHAT_DOMAIN}whatsapp-segment`, {
      headers,
    });
    return response.data; // Aquí se retorna response.data en lugar de response.json()
  } catch (error) {
    console.error('Error fetching WhatsApp Segment List:', error);
    throw error;
  }
};

export const whatsappCampaign = async () => {
  try {
    const response = await axios.get(`${RIA_CHAT_DOMAIN}whatsapp-campaign`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching WhatsApp Segment List:', error);
    throw error;
  }
};



export const whatsappContactoListStore = async (data) => {
  try {
    const response = await axios.post(`${RIA_CHAT_DOMAIN}whatsapp-contacto-list-store`, {
      name: data
    }, {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching WhatsApp Contact List Store:', error);
    throw error;
  }
};

export const whatsappContactoStore = async (data) => {
  const response = await axios.post(`${RIA_CHAT_DOMAIN}whatsapp-contacto-store`, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: data.name,
      phone: data.phone,
      country_id: data.country_id,
    }),
  });
  return response.json();
};

export const whatsappSegmentStore = async (data) => {
  const response = await axios.post(`${RIA_CHAT_DOMAIN}whatsapp-segment-store`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ title: data }),
  });
  return response.json();
};

export const whatsappCampaignStore = async (data) => {
  const response = await axios.post(`${RIA_CHAT_DOMAIN}whatsapp-campaign-store`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      campaign_name: data.campaign_name,
      template_id: data.template_id,
      contact_list_id: data.contact_list_id,
      segment_id: data.segment_id,
      schedule_time: data.schedule_time,
    }),
  });
  return response.json();
};