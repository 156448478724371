import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { PaymentFormInput } from "./PaymentFormInput";
import { PaymentFormInputSelect } from "./PaymentFormInputSelect";
import { auth } from "../../../firebase/FirebaseUtils";

const REGEX_PHONE = /^[1-9]{2}[0-9]{8,9}$/;

export function PaymentUserInformation(props) {
  const { user, paymentInfo, setPaymentInfo } = props;

  const formRef = useRef();

  useEffect(() => {
    // Actualizar paymentInfo.email con el valor de user.email al cargar el componente
    setPaymentInfo((prevPaymentInfo) => ({
      ...prevPaymentInfo,
      email: user.email,
    }));
  }, [user.email, setPaymentInfo]);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: paymentInfo,
    mode: "onChange",
  });

  function handleUserInfoSubmit() {}

  function buscarEndereco() {
    const cep = paymentInfo.cep.replace(/\D/g, '');

    if (cep) {
      const url = `https://viacep.com.br/ws/${cep}/json/`;

      fetch(url)
        .then(response => response.json())
        .then(data => {
          if (data.erro) {
            alert('CEP não encontrado!');
          } else {
            setValue("rua", data.logradouro || '');
            setValue("bairro", data.bairro || '');
            setValue("cidade", data.localidade || '');
            setValue("estado", data.uf || '');
          }
        })
        .catch(error => {
          console.error('Erro ao buscar o endereço:', error);
          alert('Erro ao buscar o endereço. Tente novamente.');
        });
    } else {
      alert('Por favor, digite um CEP válido!');
    }
  }
  const REGEX_PHONE = /^[1-9]{2}[0-9]{8,9}$/;
  
  return (
    <div className="flex flex-col">
      <h1 className="font-semibold text-lg">Datos de Facturación</h1>
      <span className="text-base"> Aviso: O e-mail deve ser confirmado para realizar a transação. Por favor, verifique sua caixa de entrada. </span>
      <form
        ref={formRef}
        onSubmit={handleSubmit(handleUserInfoSubmit)}
        className="max-w-4xl grid sm:grid-cols-2 gap-x-8 gap-y-3"
      >
        <div className="input-group">
          <PaymentFormInput
            label="CEP"
            type="text"
            register={register("cep", {
              onChange: (event) => {
                setPaymentInfo({
                  ...paymentInfo,
                  cep: event.target.value,
                });
              },
            })}
            required
          />
          <button type="button" onClick={buscarEndereco}>Auto Completar</button>
        </div>

        <PaymentFormInput
          label="Rua"
          type="text"
          register={register("rua")}
          required
        />
        <PaymentFormInput
          label="Bairro"
          type="text"
          register={register("bairro")}
          required
        />
        <PaymentFormInput
          label="Cidade"
          type="text"
          register={register("cidade")}
          required
        />
        <PaymentFormInput
          label="Estado"
          type="text"
          register={register("estado")}
          required
        />

        <PaymentFormInput
          label="Nome"
          type="text"
          register={register("name", {
            onChange: (event) => {
              setPaymentInfo({
                ...paymentInfo,
                name: event.target.value.toUpperCase(),
              });
            },
          })}
          required
        />
        <PaymentFormInput
          label="Sobrenome"
          type="text"
          register={register("lastname", {
            onChange: (event) => {
              setPaymentInfo({
                ...paymentInfo,
                lastname: event.target.value.toUpperCase(),
              });
            },
          })}
          required
        />
        <PaymentFormInput 
          label="E-mail" 
          type="email" 
          defaultValue={auth.currentUser?.email}
          {...register("email")}
          required
          readOnly
        />
        <PaymentFormInput
          label="Telefone"
          type="text"
          register={register("phone", {
            onChange: (event) => {
              setPaymentInfo({
                ...paymentInfo,
                phone: event.target.value.toUpperCase(),
              });
            },
          })}
          pattern={REGEX_PHONE}
          required
        />
      </form>
    </div>
  );
}
