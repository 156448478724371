import React from 'react';
import { format, parseISO} from 'date-fns';
import { es,pt } from 'date-fns/locale';

function ButtonsForm({ phone, email, formData, userEmail, time_bet_booking, description }) {
  const transformTimeFormat = (timeString) => {
    const [hours, minutes] = timeString.slice(0, 5).split(':');
    return `${hours}:${minutes}`;
  };
  const horaTransformada = transformTimeFormat(formData.time);

  const getConfirmationMessage = (formData) => {
    //const [customDate, customTime] = formData.date.split('T');
    let message = "*Confirmação de reserva* \n\n";
    // Formatear la fecha y la hora
    //const formattedDate = format(new Date(formData.date), 'dd MMMM yyyy', { locale: es });
    const adjustedDate = parseISO(formData.date);
    const formattedDate = format(adjustedDate, 'dd MMMM yyyy', { locale: pt });
    
    message += `*Nome:* ${formData.nombre}\n`;
    message += `*Data:* ${formattedDate}\n`;
    message += `*Hora:* ${horaTransformada}\n`;
    
    
    if (formData.adults > 0) {
      message += `Adultos: ${formData.adults}\n`;
    }
    
    if (formData.teen > 0) {
      message += `Bebês: ${formData.teen}\n`;
    }
    
    if (formData.kids > 0) {
      message += `Crianças: ${formData.kids}\n`;
    }
    
    // Agrega el campo de email solo si tiene valor
    if (formData.email.trim() !== "") {
      message += `Email: ${formData.email}\n`;
    }
    message += `Telefone: +${formData.phone}\n\n`;
    if (formData.description.trim() !== "") {
      message += `Descrição: ${formData.description}\n\n`;
    }
    message += `Código de Confirmação: ${formData.code}\n\n`;
  
    // Agrega otros campos según sea necesario...
  
    return message;
  };

  const sendWhatsAppMessage = () => {
    const message = getConfirmationMessage(formData);
    const whatsappLink = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, '_blank');
  };


  const sendEmailMessage = () => {
    const message = getConfirmationMessage(formData);
    const emailLink = `mailto:${email}?subject=Confirmación%20de%20Reserva&body=${encodeURIComponent(message)}`;
    window.open(emailLink, '_blank');
  };

  const generateOutlookCalendarLink = ( formData) => {
    // Construir el cuerpo del evento con todos los detalles del formulario
    const [hours, minutes] = formData.time.split(':');
    const formattedCustomTime = `${hours}:${minutes}`;
    

    const eventDetails = `Nome: ${formData.nombre}<br>` +
    `Data: ${formData.date}<br>` +
    // Otras propiedades...
    `Hora: ${formattedCustomTime}<br>` +
    `Adultos: ${formData.adults}<br>` +
    `${formData.teen !== 0 ? `Bebês: ${formData.teen}<br>` : ''}` +
    `${formData.kids !== 0 ? `Crianças: ${formData.kids}<br>` : ''}` +
    `Email: ${formData.email}<br>` +
    `Telefone: +${formData.phone}<br>` +
    `Código de Confirmação: ${formData.code}`;
    // Construir el enlace de Outlook Calendar con los datos del formulario
    const outlookCalendarLink = `https://outlook.live.com/owa/?path=/calendar/action/compose&startdt=${formData.date}T${formData.time}&subject=${encodeURIComponent(formData.nombre)}&body=${encodeURIComponent(eventDetails)}`;
  
    return outlookCalendarLink;
  };
  
  // [customDate, customTime] = formData.date.split('T');
  const handleOutlookCalendarClick = () => {
  const outlookCalendarLink = generateOutlookCalendarLink( formData);
  window.open(outlookCalendarLink, '_blank');
};




const handleGoogleCalendarClick = () => {
  // Obtener la fecha y hora por separado
  //const [customDate, customTime] = formData.date.split('T');
  
  // Lógica para crear evento en Google Calendar y abrir enlace
  // Utiliza los datos del formulario
  const googleCalendarLink = generateGoogleCalendarLink(formData.date, formData.time, formData.nombre);

  // Abre el enlace en una nueva ventana
  window.open(googleCalendarLink, '_blank');
};

const generateGoogleCalendarLink = (date, time, nombre) => {
  


  // Obtener la hora y los minutos en formato HH:mm
  const [hours, minutes] = time.split(':');
  const formattedCustomTime = `${hours}:${minutes}`;

  // Combinar customDate y formattedCustomTime
  const combinedDateTime = `${date}T${formattedCustomTime}`;

  // Obtener el formato UTC de la fecha y hora de inicio
  const startDateTime = new Date(combinedDateTime).toISOString().replace(/[-:.]/g, '');

  // Obtener el formato UTC de la fecha y hora de finalización (sumar 1 hora a la hora de inicio)
  const endDateTime = new Date(combinedDateTime);
  endDateTime.setMinutes(endDateTime.getMinutes() + time_bet_booking);
  const formattedEndDate = endDateTime.toISOString().replace(/[-:.]/g, '');

  // Generar el mensaje de confirmación
  const confirmationMessage = getConfirmationMessage(formData);


  // Construir el enlace de Google Calendar con detalles adicionales
  const additionalDetails = `${confirmationMessage}`;
  //const googleCalendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&dates=${startDateTime}/${formattedEndDate}&text=${encodeURIComponent(nombre)}&details=Detalles+de+la+reserva`;
  const googleCalendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&dates=${startDateTime}/${formattedEndDate}&text=${encodeURIComponent(nombre)}&details=${encodeURIComponent(`${additionalDetails}`)}`;
  return googleCalendarLink;
};






  return (
    <div className="flex max-w-screen-md bg-neutral-100 rounded-full mx-auto p-4 justify-center mb-3">
        <div
        id="send_whatsapp"
        className={` text-white  ml-2 bg-green-500 cursor-pointer hover:bg-green-400 rounded-lg font-medium text-sm p-1 px-10 py-1 `}
        onClick={() => sendWhatsAppMessage()} 
      >
      Whatsapp</div>
      <div
      id="send_email"
      className={`text-white ml-2 bg-purple-500 cursor-pointer hover:bg-purple-400 rounded-lg font-medium text-sm p-1 px-10 py-1 `}
      onClick={() => sendEmailMessage()} 
    >
      Email</div>
      <div
        id="save_google_event"
        className={` text-white  ml-2 bg-red-500 cursor-pointer hover:bg-red-400 rounded-lg font-medium text-sm p-1 px-10 py-1 `}
        onClick={handleGoogleCalendarClick} 
      >
      Google</div>
      <div
        id="save_outlook-event"
        className={`  text-white  ml-2 bg-blue-500 cursor-pointer hover:bg-blue-400 rounded-lg font-medium text-sm p-1 px-10 py-1`}
        onClick={handleOutlookCalendarClick}
      >
      Outlook</div>

    </div>
  )
}

export  {ButtonsForm}