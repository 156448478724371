import React from 'react';
import jsPDF from 'jspdf';

const Reporte = ({ transaction }) => {
  const data = transaction;
  if (!data) {
    return <div>Nenhum dado selecionado.</div>;
  }

  const generarPDF = () => {
    const doc = new jsPDF();
    const now = new Date();
    const formattedDate = `${String(now.getDate()).padStart(2, '0')}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getFullYear()).slice(-2)}`;
    const formattedTime = `${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;

    let yPos = 12;
    doc.setFont('helvetica');
    doc.setFontSize(10);

    yPos += 6;
    doc.setFont('helvetica', 'bold');
    doc.text('Relatório de vendas', 10, 10);
    doc.setFont('helvetica', 'normal');
    doc.text(`Nº: ${data.id}`, 10, yPos);
    yPos += 6;
    doc.text(`Data: ${data.date}`, 10, yPos);
    yPos += 6;
    doc.text(`Código de Transação: ${data.codigoTransaccion}`, 10, yPos);
    yPos += 12;

    doc.setFont('helvetica', 'bold');
    doc.text(`Comprador:`, 10, yPos);
    doc.text(`Documento:`, 10, yPos + 6);
    doc.text(`Email:`, 10, yPos + 12);
    doc.text(`Telefone:`, 10, yPos + 18);
    doc.text(`Endereço:`, 10, yPos + 24);
    doc.setFont('helvetica', 'normal');
    doc.text(`${data.nombreComprador} ${data.apellidoComprador}`, 35, yPos);
    doc.text(`${data.documentoComprador}`, 35, yPos + 6);
    doc.text(`${data.customer_user_email}`, 30, yPos + 12);
    doc.text(`${data.customer_user_phone_number}`, 35, yPos + 18);
    doc.text(`${data.direccionComprador}, ${data.ciudad}`, 35, yPos + 24);
    yPos += 36;

    doc.setFont('helvetica', 'bold');
    doc.text(`Descrição: ${data.descripcion}`, 10, yPos);
    yPos += 6;
    doc.text('Detalhe:', 10, yPos);
    doc.setFont('helvetica', 'normal');
    yPos += 6;

    yPos += 6;

    if (data.discount_value) {
      doc.setFont('helvetica', 'bold');
      doc.text(`Desconto:`, 10, yPos);
      doc.setFont('helvetica', 'normal');
      doc.text(`${data.discount_value }BLR`, 35, yPos);
      yPos += 6;
    }

    doc.setFont('helvetica', 'bold');
    doc.text(`Quantia:`, 10, yPos);
    doc.setFont('helvetica', 'normal');
    doc.text(`${data.monto} ${data.moneda}`, 35, yPos);
    yPos += 12;
    yPos += 6;
    doc.line(10, yPos, 200, yPos);

    doc.save(`soueou-ventas${formattedDate}${formattedTime}.pdf`);
  };

  const textGray600 = 'text-neutral-800 bg-white text-sm ';

  return (
    <div className="p-4 sm:w-full lg:w-full bg-white rounded-md shadow-lg border border-neutral-200">
      <button onClick={generarPDF} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">Generar PDF</button>
      <div className="p-2 rounded-lg">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center">
            <p className={`${textGray600} mr-2`}>Nº:</p>
            <h5 className={`${textGray600} font-bold`}>{data.id}</h5>
          </div>
          <div>
            <p className={`${textGray600}`}><strong>Data:</strong> {data.date}</p>
            <p className={`${textGray600}`}><strong>Código de Transação:</strong> {data.codigoTransaccion}</p>
          </div>
        </div>
        <hr className="my-2" />
        <div>
          <div className="grid grid-cols-2 gap-4">
            <p className={`${textGray600}`}><strong>Comprador:</strong> {data.nombreComprador} {data.apellidoComprador}</p>
            <p className={`${textGray600}`}><strong>Documento:</strong> {data.documentoComprador}</p>
          </div>
          <hr className="my-2" />
          <p className={`${textGray600}`}><strong>Email:</strong> {data.customer_user_email}</p>
          <p className={`${textGray600}`}><strong>Telefone:</strong> {data.customer_user_phone_number}</p>
          <p className={`${textGray600}`}><strong>Endereço:</strong> {data.direccionComprador}, {data.ciudad}</p>
        </div>
        <hr className="my-2" />
        <div>
          <p className={`${textGray600}`}><strong>Descrição:</strong> {data.descripcion}</p>
          <hr className="my-2" />
          {data.discount_value && (
            <p className={`${textGray600}`}><strong>Desconto:</strong> {data.discount_value} {data.moneda}</p>
          )}
          <p className={`${textGray600}`}><strong>Quantia:</strong> {data.monto} {data.moneda}</p>
        </div>
        <hr className="my-2" />
      </div>
    </div>
  );
};

export default Reporte;
