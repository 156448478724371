import axios from 'axios';
import { DOMAIN, LOCALHOST_DOMAIN } from "../../../config/Constant"; // Asegúrate de que esta ruta sea correcta y que DOMAIN esté configurado correctamente.

export const createButton = async (data, token) => {
    try {
        const response = await axios.post(`${DOMAIN}ecommerce/buttons/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getButtonsByCustomerUser = async (customerUserId) => {
    try {
      const response = await axios.get(`${DOMAIN}ecommerce/get/${customerUserId}/`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const updateButton = async (buttonId, updatedData, token) => {
    try {
        const response = await axios.put(`${DOMAIN}ecommerce/buttons/${buttonId}/`, updatedData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteButton = async (buttonId, token) => {
    try {
        const response = await axios.delete(`${DOMAIN}ecommerce/buttons/${buttonId}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
