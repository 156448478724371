import { useSelector } from "react-redux";
import MockImage from "../../assets/landing-page/mockups/phone.png";
import { Navbar } from "./Components/Navbar";

import "./Components/LandingPageStyles.css";
import { BenefitsCard } from "./Components/BenefitsCard";

import {
  FaShareAlt,
  FaCreditCard,
  FaQrcode,
  FaChartLine,
  FaFeatherAlt,
  FaQuoteLeft,
  FaWhatsapp,
} from "react-icons/fa";

import cardSVG from "../../assets/landing-page/decoration/card.svg";
import commentSVG from "../../assets/landing-page/decoration/comment.svg";
import signalSVG from "../../assets/landing-page/decoration/signal.svg";

import virtualCard from "../../assets/landing-page/decoration/plan-virtual2.png";
import basicCard from "../../assets/landing-page/decoration/plan-simple-modelo.png";
import customCard from "../../assets/landing-page/decoration/plan-medio-modelo.png";
import customCardExample from "../../assets/landing-page/decoration/plan-medio-capacitar.png";
import fullDesignCard from "../../assets/landing-page/decoration/plan-avanzado-modelo.png";
import fullDesignCardExample from "../../assets/landing-page/decoration/plan-avanzado-gamefic.png";
import nfc from '../../assets/landing-page/decoration/nfc.png';

import firstStep from "../../assets/landing-page/steps/first_step.jpeg";
import payStep from "../../assets/landing-page/steps/pay_step.jpeg";
import customizeStep from "../../assets/landing-page/steps/customize_step.jpeg";
import receiveStep from "../../assets/landing-page/steps/receive_step.jpeg";

import { GiEarthAmerica } from "react-icons/gi";
import { ProductsCard } from "./Components/ProductsCard";
import { StepsCard } from "./Components/StepsCard";
import { ReviewCard } from "./Components/ReviewCard";
import { ReviewsList } from "./Components/ReviewsList";
import { Footer } from "./Components/Footer";
import { FrequentlyAsked } from "./Components/FrequentlyAsked";
import { FrequentQuestion } from "./Components/FrequentQuestion";
import { ROUTER_PAYMENT } from "../../config/Constant";
import { useVerifyToken } from "../payment_page/Hooks/useVerifyToken";

import TokenRefresher from "../../components/TokenRefresher";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { scrollToById } from "./Services/Utils/scrollToById";
import { ClientsCard } from './Components/ClientsCard';

export function LandingPage(props) {

  const token = useSelector((state) => state.login.token);
  const { isVerified, loading, user } = useVerifyToken(token);

  const handleButtonClick = () => {
    // Codificar el mensaje para que sea compatible con la URL
    const message = encodeURIComponent("Olá, gostaria de saber mais sobre o SouEu e seus cartões digitais!");
    const phoneNumber = "5513997076287";
    const url = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${message}`;

    window.open(url, "_blank");
};

  const location = useLocation();
  const navigate = useNavigate();

  function navigateToProductById(id) {
    return () => {
      navigate(`${ROUTER_PAYMENT}?id=${id}`);
    };
  }

  useEffect(() => {
    const state = location.state;
    if (state?.to) {
      scrollToById(state.to)();
    }
  }, []);

  
  return (
    <>

      <TokenRefresher />
      <div id="start"
        className="radial-background relative flex flex-col justify-between w-full"
      >
        <div className="absolute h-full w-full pointer-events-none">
          <img
            className="card-animation neon-light-animation h-48 absolute"
            src={cardSVG}
            alt=""
          />
          <img
            className="comment-animation neon-light-animation h-48 absolute"
            src={commentSVG}
            alt=""
          />
          <img
            className="signal-animation neon-light-animation h-48 absolute"
            src={signalSVG}
            alt=""
          />
        </div>

        <Navbar user={user} />
        <div className="flex flex-col lg:flex-row">
        <button icon={FaWhatsapp}
          className="fixed bottom-4 right-4 p-2 bg-green-500 text-white rounded-full  z-50"
          onClick={handleButtonClick}> 
          <FaWhatsapp className="text-4xl" />
        </button>

        

          <div className="px-12 2xl:pb-24 self-center mx-auto max-w-2xl 2xl:max-w-4xl text-center lg:text-left text-stone-50">
            <h1 className="mt-4 lg:mt-0 text-3xl lg:text-[40px] 2xl:text-5xl xl:leading-tight">
            Obtenha seu cartão <strong> digital </strong> al
              <strong> instantaneamente </strong>
            </h1>

            <p className="mt-6 leading-loose text-xl 2xl:text-2xl 2xl:leading-normal">
              <strong> SouEu Digital </strong> é um cartão de visita digital que permite 
            você compartilhar <strong> quem você é</strong>, com qualquer pessoa <strong>instantaneamente. </strong>
            </p>

            <div className="mt-12">
              <Link
                to={ROUTER_PAYMENT}
                className="px-16 md:px-24 py-3 text-center text-base rounded-2xl border border-transparent text-black font-semibold bg-stone-50 transition-colors hover:border-stone-500 hover:bg-stone-900 hover:text-stone-50"
              >
                CRIE SEU CARTÃO
                
              </Link>
            </div>
          </div>

          <div className="mt-16 lg:mt-4 lg:mr-36 p-12 max-w-[24rem]  h-fit relative mx-auto rounded-t-[3rem] bg-white">
            <div className="left">
              <div></div>
            </div>
            <div className="right">
              <div></div>
            </div>
            <img src={MockImage} alt="SoyYo app" className="mx-auto" />
          </div>
        </div>
      </div>

      <div id="benefits" className="pb-32 pt-20 font-montserrat text-stone-90">
        <h2 className="text-2xl lg:text-3xl text-center">
        Benefícios do SouEu Digital
        </h2>

        <div className="mt-12 px-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-center justify-items-center h-fit gap-y-16 sm:gap-y-24 gap-x-12">
          <BenefitsCard
            title="Compartilhe"
            description="Compartilhe seu cartão personalizado com potenciais clientes e contatos"
            icon={<FaShareAlt />}
          />
          <BenefitsCard
            title="Cartão"
            description="Utilize um cartão inteligente com tecnologia NFC"
            icon={<FaCreditCard />}
          />
          <BenefitsCard
            title="QR"
            description="Mostre suas informações de contato em todos os lugares com um QR"
            icon={<FaQrcode />}
          />
          <BenefitsCard
            title="Melhorando"
            description="Melhorando constantemente para aumentar a experiência do usuário"
            icon={<FaChartLine />}
          />
          <BenefitsCard
            title="Simples"
            description="Personalize seu cartão facilmente com nossa interface amigável"
            icon={<FaFeatherAlt />}
          />
          <BenefitsCard
            title="Ecológico"
            description="Reduza o uso de papel de cartões de visita físicos"
            icon={<GiEarthAmerica />}
          />
        </div>
      </div>

      <div id="products"
        className="pb-8 sm:pb-32 pt-24 flex flex-col font-montserrat bg-stone-100"
      >
        <h2 className="px-4 text-2xl lg:text-3xl text-center">
        Nossos Produtos
        </h2>

        <div className="mt-12 px-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 items-center justify-items-center h-fit gap-y-20 gap-x-12">
          {/* TODO: Refactorizar con defaultProducts.js */}
          {/* <ProductsCard
            image={basicCard}
            hoverText={
              <>
                ¡Peça uma!
                <br />
                
              </>
            }
            title="Cartão Básico"
            description="Conecte-se com nossa tecnologia"
            buttonText={
              <div className="flex ">
                <p className="font-normal">Peça uma&nbsp;|&nbsp;</p>
                <p className="font-bold text-base"> R$ 185</p>

              </div>
            }
            link={`/#${ROUTER_PAYMENT}?id=2`}
            onClickImageOrButton={navigateToProductById(2)}
          /> */}
         {/*  <ProductsCard
            
            image={virtualCard}
            hoverText={
              <>
                ¡Peça uma!
                <br />
                + NFC tag
              </>
            }
            title="Cartão Digital"
            description="Desfrute de um cartão digital"
            buttonText={
              <div className="flex ">
                <p className="font-normal">Peça uma&nbsp;|&nbsp;</p>
                <p className="font-bold text-base"> R$ 215</p>

              </div>
            }
            link={`/#${ROUTER_PAYMENT}?id=1`}
            onClickImageOrButton={navigateToProductById(5)}
          /> */}
        
          <ProductsCard
            image={customCardExample}
            hoverImage={customCard}
            hoverText={
              <>
                ¡Peça uma!
                <br />
                
              </>
            }
            title="Cartão Customizado"
            description="Sua marca, nossa tecnologia"
            buttonText={
              <div className="flex ">
                <p className="font-normal">Peça uma&nbsp;|&nbsp;</p>
                <p className="font-bold text-base"> R$ 89.99</p>

              </div>
            }
            link={`${ROUTER_PAYMENT}?id=3`}
            onClickImageOrButton={navigateToProductById(3)}
          />
          <ProductsCard
            image={fullDesignCardExample}
            hoverImage={fullDesignCard}
            hoverText={
              <>
                ¡Peça uma!
                <br />
                
              </>
            }
            title="Cartão Design Completo"
            description="Controle total sobre seu cartão e sua marca"
            buttonText={
              <div className="flex ">
                <p className="font-normal">Peça uma&nbsp;|&nbsp;</p>
                <p className="font-bold text-base"> R$ 129.99 </p>

              </div>
            }
            link={`${ROUTER_PAYMENT}?id=4`}
            onClickImageOrButton={navigateToProductById(4)}
          />
         {/*  <ProductsCard
            image={comboFullDesignCard}
            hoverImage={fullDesignCard}
            hoverText="¡Pide una!"
            title="Pack Full Design"
            description="Personaliza la imagen de tu tarjeta"
            buttonText="Pide una | 260Bs."
            link={`/#${ROUTER_PAYMENT}?id=5`}
            onClickImageOrButton={navigateToProductById(5)}
          />
          <ProductsCard
            image={comboNFCCard}
            hoverImage={fullDesignCard}
            hoverText="¡Pide una!"
            title="Pack Tarjeta NFC"
            description="TAG + NFC"
            buttonText="Pide una | 310Bs."
            link={`/#${ROUTER_PAYMENT}?id=6`}
            onClickImageOrButton={navigateToProductById(6)}
          /> */}
        </div>
      </div>

      <div id="steps"
        className="pb-32 pt-20 flex flex-col font-montserrat bg-stone-100"
      >
        <h2 className="px-4 text-2xl lg:text-3xl font-semibold text-center">
        Passos para conseguir seu cartão
        </h2>

        <div className="mt-6 sm:mt-12 px-8 grid grid-cols-1 sm:grid-cols-2 items-center justify-items-center h-fit gap-y-16 sm:gap-y-20 gap-x-12">
          <StepsCard
            image={firstStep}
            index="1"
            description="Escolha o plano SouEu que melhor se adapta às suas necessidades"
          />
          <StepsCard
            image={payStep}
            index="2"
            description="Registre-se e realize o pagamento do serviço em nosso site"
          />
          <StepsCard
            image={customizeStep}
            index="3"
            description="Personalize seu perfil na sua conta SouEu"
          />
          <StepsCard
            image={receiveStep}
            index="4"
            description="¡Receba seu cartão e compartilhe com todos! (não se esqueça de plantar as sementes)"
          />
        </div>
      </div>

      {/* <div id="clients" className="pb-32 pt-20 flex flex-col font-montserrat bg-stone-100">
        <h2 className="px-4 text-2xl lg:text-3xl text-center">Nuestros Clientes</h2>
        <p className="text-center text-gray-600 mb-12">Conoce a nuestros clientes</p>
        <div className="text-center">
          <ClientsCard />
        </div>
      </div> */}

      <div id="comments" className="pb-16 lg:pb-32 relative flex flex-col font-montserrat">
        <div className="absolute left-10 top-1/3">
          <FaQuoteLeft className="text-8xl opacity-10" />
        </div>
        <h2 className="mt-12 lg:mt-24 px-4 text-2xl md:text-3xl lg:text-4xl font-medium text-center">
        O que as pessoas dizem sobre nós
        </h2>
        <div className="mt-16 lg:mt-32">
          <ReviewsList
            reviews={[
              <ReviewCard
                nStars={4}
                starColor="rgb(250,10,0)"
                review='"SouEu é simplesmente incrível. Adoro a forma como posso compartilhar minhas informações de contato com apenas um toque."'
                author="David Lopez"
              />,
              <ReviewCard
                nStars={5}
                starColor="rgb(250,220,50)"
                review=' "A qualidade dos cartões de visita digitais com NFC da SouEu são excepcionais. O design é elegante e moderno.".'
                author="Mario"
              />,
              <ReviewCard
                nStars={5}
                starColor="rgb(250,50,200)"
                review='"Definitivamente recomendaria SouEu a qualquer um que esteja procurando uma forma inovadora de se apresentar.".'
                author="Valentina"
              />,
            ]}
          />
        </div>
      </div>

      <div id="questions" className="pb-32 pt-12 lg:pt-24 flex justify-center text-stone-900 bg-stone-100">
        <FrequentlyAsked
          categories={[
            {
              title: "Cartões de contato NFC",
              element: (
                <>
                  <FrequentQuestion
                    question="O que é um cartão inteligente NFC?"
                    answer="É um cartão com um chip NFC que permite transferir suas informações de contato para dispositivos compatíveis"
                  />
                  <FrequentQuestion
                    question="Que informações podem ser compartilhadas com meu cartão?"
                    answer="De tudo! Você pode compartilhar facilmente seu número de telefone, nome, imagens, site da sua empresa e editá-los a qualquer momento a partir do seu perfil. "
                  />
                  <FrequentQuestion
                    question="A outra pessoa precisa de um aplicativo para receber minhas informações?"
                    answer="Não! Seu perfil é aberto em um navegador (Chrome, Firefox, Opera, etc.), o qual qualquer smartphone possui."
                  />
                </>
              ),
            },
            {
              title: "Design do cartão",
              element: (
                <>
                  <FrequentQuestion
                    question="Posso ter um cartão SouEu com meu logo?"
                    answer="Claro! O plano 'Design Completo' é perfeito para você, permitindo que você personalize o design do seu cartão ao máximo com a ajuda de nossos designers, e o design final será aprovado por você."
                  />
                  <FrequentQuestion
                    question="Qual a diferença entre o plano 'Básico' e 'Design Completo'?"
                    answer="A diferença mais importante está no design do cartão. Com o plano 'Design Completo', você pode ter qualquer cor ou logotipo. Enquanto com o plano 'Básico', o logo da SouEu estará impresso no cartão."
                  />
                </>
              ),
            },
            {
              title: "Para empresas",
              element: (
                <>
                  <FrequentQuestion
                    question="Posso comprar vários cartões SouEu?"
                    answer="Sim. Você pode pedir quantos desejar. Inclusive, você pode gerenciar vários cartões a partir de uma interface administrativa."
                  />
                  <FrequentQuestion
                    question="Quais as vantagens da interface administrativa?"
                    answer="Você terá acesso ao perfil de todos os seus colaboradores, onde poderá editá-los, alterar configurações da conta, analisar estatísticas e muito mais."
                  />
                </>
              ),
            },
          ]}
        />
      </div>


      <Footer />
    </>
  );
}
