import imageCompression from "browser-image-compression";

export async function uploadImage(files, onLoad) {
  const fileReader = new FileReader();
  if (fileReader && files && files.length > 0) {
    const reader = new FileReader();
    reader.onload = async () => {
      await onLoad(reader);
    };
    reader.readAsDataURL(files[0]);
  }
}

export async function compressImage(files, maxSizeMB=1, maxWidthOrHeight=600) {
  const imageBlob = files[0];
  const options = {
    maxSizeMB: maxSizeMB,
    maxWidthOrHeight: maxWidthOrHeight,
    useWebWorker: true,
    fileType: "image/jpeg",
  };
  try {
    const compressedBlob = await imageCompression(imageBlob, options);
    let compressedFile = new File([compressedBlob], compressedBlob.name);
    return compressedFile;
  } catch (error) {
    console.error(error);
  }
}


export async function compressAvatarImageFromCanvas(imageBlob) {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    fileType: "image/jpeg",
  };
  try {
    const compressedBlob = await imageCompression(imageBlob, options);
    let fileName = Date.now().toString(36) + Math.random().toString(36).substring(2);
    let compressedFile = new File([compressedBlob], fileName + ".jpg");
    return compressedFile;
  } catch (error) {
    console.error(error);
  }
}

export async function uploadFile(files, onLoad) {
  const fileReader = new FileReader();
  if (fileReader && files && files.length > 0) {
    const reader = new FileReader();
    reader.onload = async () => {
      await onLoad(reader);
    };
    reader.readAsDataURL(files[0]);
  }
}

export async function handleFileUpload(event, setFileSource, setFileFormData) {
  event.preventDefault();
  const files = event.target.files;
  const file = files[0];
  const fileType = file.type;

  if (fileType === "application/pdf" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || fileType === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
    uploadFile(files, async (reader) => {
      setFileSource(URL.createObjectURL(file));
      setFileFormData(file);
    });
  } else {
    console.log("Unsupported file type");
  }
}