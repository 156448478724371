import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import  { ConfBookingCreateView } from '../../Api/Booking';
import { toast } from 'react-toastify';

const BookingNewConfig = ({ isOpen, onClose, id, token, updateBookingData  }) => {
  
  const modalClass = isOpen ? 'block' : 'hidden';

  const [formState, setFormState] = useState({
    btn: '',
  });

  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));

    // Validar el límite de caracteres
    if (value.length > 20) {
      setError('O título não pode ter mais de 20 caracteres.');
    } else {
      setError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar el límite de caracteres antes de enviar el formulario
    if (formState.btn.length > 20) {
      setError('O título não pode ter mais de 20 caracteres.');
    } else {
      try {

        const dataToSend = {
          ...formState,
          btn: formState.btn,
          status_conf: '1',
          phone: '',
          email: '',
          max_personas: '1',
          time_bet_booking:  '30',
          max_reservas:  '1',
          holiday:  "[true, true, true, true, true, true, true]",
          hora_inicio:  '08:00:00',
          hora_fin: '11:30:00',
          hora_inicio_tarde: '14:30:00',
          hora_fin_tarde:  '18:00:00',
          hora_inicio_noche: '19:00:00',
          hora_fin_noche:  '19:00:00',
          kids:  false,
          teen:  false,
          customer_user: id,
        };

        const response = await ConfBookingCreateView(dataToSend, id, token);
        
        setFormState((prevFormState) => ({
          ...prevFormState,
          btn: '', // Vaciar el contenido del input
        }));

        onClose();
        toast.success("Novo horário criado", {
          position: "top-center",
          bodyClassName: "text-sm",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
        });
        updateBookingData();
      } catch (error) {
        // Manejar errores en la llamada al API, por ejemplo, mostrar un mensaje de error
        console.error('Erro ao enviar o formulário para API:', error);
        setError('Erro ao enviar o formulário para API');
      }
    }
  };

  const handleClose = () => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      btn: '', // Vaciar el contenido del input
    }));
  
    onClose();
  };

  return (
    <div className={`fixed inset-0 overflow-y-auto z-20 ${modalClass}`}>
      <div className="flex items-end justify-center max-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity " aria-hidden="true" onClick={onClose}>
          <div className="absolute inset-0 bg-black opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:max-w-md">
          
          <form onSubmit={handleSubmit}>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-btn">
                Novo Horário
              </h3>
              <div className="mt-2">
                <label htmlFor="btn" className="block text-sm font-medium text-gray-700">
                  Título:
                </label>
                <input
                  type="text"
                  id="btn"
                  name="btn"
                  value={formState.btn}
                  onChange={handleInputChange}
                  maxLength={20}
                  className={`mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 w-full ${
                    error ? 'border-red-500' : ''
                  }`}
                  placeholder="Inserir o título"
                />
                {error && <p className="text-xs text-red-500">{error}</p>}
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-center">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent m-2 shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Criar
              </button>
              <button
                onClick={handleClose}
                type="button"
                className="w-full inline-flex justify-center rounded-md border px-4 py-2 bg-red-500 m-2 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { BookingNewConfig };
