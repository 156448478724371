import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { FaTrash } from 'react-icons/fa'; 
import { toast } from 'react-toastify';
import ConfirmationModal from '../ConfirmationModal';

const BookingEdit = ({ booking, onUpdateBooking, kids, teen, userEmail, formattedTime  }) => {

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationAction, setConfirmationAction] = useState(null);

  const openConfirmationModal = (message, action) => {
    setConfirmationMessage(message);
    setConfirmationAction(() => action);
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setConfirmationMessage('');
    setConfirmationAction(null);
  };

  const confirmAction = () => {
    if (confirmationAction) {
      confirmationAction();
    }
    closeConfirmationModal();
  };

  const [isOpen, setIsOpen] = useState(true);

  const [dateFormData, setDateFormData] = useState('');
  const handleDeleteBooking = () => {
    // Cambiar el estado de la reserva a 5 (Terminado)
    setFormData({
      ...formData,
      status_booking: 5,
    });

    // Actualizar la reserva con el nuevo estado

    toast.dark("Actualizción Eliminada", {
      position: "top-center",
      bodyClassName: "text-sm",
      autoClose: 1000,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      theme: "colored",
    });
    onUpdateBooking(formData.id, { status_booking: 5 });
  };

  const [formData, setFormData] = useState({
    nombre: '',
    phone: '',
    //date: '',
    adults: '',
    teen:'',
    kids: '',
    email: '',
    description: '',
    id: '',
    code:'',
    created_at: '',
    status_booking: '',

  });


  useEffect(() => {
    if (booking) {
      setFormData({
        nombre: booking.nombre || '',
        phone: booking.phone || '',
        //date: booking.date ? format(new Date(booking.date), "yyyy-MM-dd'T'HH:mm:ss") : '',
        adults: booking.adults || '',
        teen: booking.teen || '',
        kids: booking.kids || '',
        email: booking.email || '',
        description: booking.description || '',
        id: booking.id || '',
        code: booking.code || '',
        created_at: booking.created_at ? format(new Date(booking.created_at), "yyyy-MM-dd'T'HH:mm:ss") : '',
        status_booking: booking.status_booking || '',
        time: formattedTime || '',

      });
      setDateFormData(booking.date || '');
    }
  }, [booking,formattedTime]);

  useEffect(() => {
    
  }, [kids, teen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValue =
      (name === 'status_booking' || name === 'kids' || name === 'teen') && value === ''
        ? 0
        : value;

    if (name === 'date') {
      // Manejar cambios específicos de la fecha
      setDateFormData(value);
    } else {
      // Para otros campos del formulario
      setFormData({
        ...formData,
        [name]: updatedValue,
      });
    }
  };

  const handleTimeChange = (e) => {
    // Manejar cambios en el campo de tiempo
    setFormData({
      ...formData,
      time: e.target.value,
    });
  };
 


  const handleStatusChange = (e) => {
    // Manejar el cambio del combobox de estado específicamente
    setFormData({
      ...formData,
      status_booking: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const adjustedFormData = {
      ...formData,
      status_booking: formData.status_booking === '' ? 0 : formData.status_booking,
      kids: formData.kids === '' ? 0 : formData.kids,
      teen: formData.teen === '' ? 0 : formData.teen,
      date: dateFormData + 'T' + formData.time + ':00Z', // Agregar el valor de la fecha al objeto ajustado
    };

    onUpdateBooking(formData.id, adjustedFormData);
    toast.success("Atualização Realizada", {
      position: "top-center",
      bodyClassName: "text-sm",
      autoClose: 1000,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      theme: "colored",
    });
  };

  const labelClass = 'block text-gray-700 text-xs font-semibold text-left';
  const inputClass = 'form-input block  border-2 border-stone-400 rounded-md text-base p-2 bg-white';

  const handleConfirm = () => {
    openConfirmationModal('Confirmar reserva?', () => {
      // Actualizar la reserva con el nuevo estado (Confirmado: 1)
      onUpdateBooking(formData.id, { status_booking: 1 });
      setIsOpen(false);
    });
  };
  
  const handleReject = () => {
    openConfirmationModal('Rechazar reserva?', () => {
      // Actualizar la reserva con el nuevo estado (Cancelado: 2)
      onUpdateBooking(formData.id, { status_booking: 2 });
      setIsOpen(false);
    });
  };

  return (

    <form onSubmit={handleSubmit} className="max-w-4xl bg-neutral-100 rounded-lg mx-auto p-4">
      <div className="flex flex-col md:flex-row mb-3">
        <div className="w-full md:w-1/6 px-3">
          <label htmlFor="code" className={labelClass}>
            Código:  {formData.code}
          </label>
        </div>
        <div className="w-full md:w-3/6 px-3">
          <label htmlFor="created_at" className={labelClass}>
            Data de criação: {formData.created_at}
          </label>
        </div>
        
        
      </div>

      <div className="flex flex-col md:flex-row mb-3">
        <div className="flex w-1/3">
          <div className="w-full">
            <label htmlFor="nombre" className={labelClass}>
              Nome:
            </label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              className={inputClass}
            />
          </div>
          
        </div>
        <div className="flex w-1/3">
          <div className="w-full">
            <label htmlFor="phone" className={labelClass}>
              Telefone:
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className={inputClass}
            />
          </div>
        </div>
        <div className="flex w-1/3">
          <div className="w-full">
            <label htmlFor="email" className={labelClass}>
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={inputClass}
            />
          </div>
        </div>
      </div>


      <div className="flex flex-col md:flex-row  mb-3">
        <div className="w-1/3"> 
        <label htmlFor="date" className={labelClass}>
            Data y Hora:
          </label>
          <input
            type="text"
            id="date"
            name="date"
            value={dateFormData}
            onChange={handleChange}
            className={inputClass}
          />
        </div>
        <div className="w-1/3">
          <label htmlFor="time" className={labelClass}>
            Hora:
          </label>
          <input
            type="text"
            id="time"
            name="time"
            value={formData.time}
            onChange={handleTimeChange}
            className={inputClass}
          />
        </div>
        <div className="w-1/3">
          <label htmlFor="status" className={labelClass}>
            Estado:
          </label>
          <select
            id="status"
            name="status"
            value={formData.status_booking}
            onChange={handleStatusChange}
            className={inputClass}
          >
            <option value="0" className='bg-blue-500 text-white rounded-md'>Em Espera</option>
            <option value="1" className='bg-green-500 text-white rounded-md'>Aceptado</option>
            <option value="2" className='bg-red-500 text-white rounded-md'>Cancelado</option>
            <option value="3" className='bg-orange-500 text-white rounded-md'>Em Curso</option>
            <option value="4" className='bg-purple-500 text-white rounded-md'>Terminado</option>
          </select>
        </div>
        </div>

      <div className="flex flex-col md:flex-row mb-3">
        <div className=" w-1/3">
          <label htmlFor="adults" className={labelClass}>
            Adultos:
          </label>
          <input
            type="text"
            id="adults"
            name="adults"
            value={formData.adults}
            onChange={handleChange}
            className={inputClass}
          />
        </div>
        {teen && (
        <div className=" w-1/3">
          <label htmlFor="teen" className={labelClass}>
            Bebês:
          </label>
          <input
            type="text"
            id="teen"
            name="teen"
            value={formData.teen}
            onChange={handleChange}
            className={inputClass}
          />
        </div>
        )}
        {kids && (
        <div className=" w-1/3">
          <label htmlFor="kids" className={labelClass}>
          Crianças:
          </label>
          <input
            type="text"
            id="kids"
            name="kids"
            value={formData.kids}
            onChange={handleChange}
            className={inputClass}
          />
        </div>
        )}
      </div>


      <div className="flex flex-col md:flex-row mb-3">
        <div className="w-4/5"> {/* Ajusta el ancho del contenedor */}
          <label htmlFor="description" className={labelClass}>
            Descrição:
          </label>
          <textarea
            type="text"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="form-input block border-2 border-stone-400 rounded-md text-base p-2 bg-white w-full " // Ajusta la clase aquí
          />
        </div>
      </div>



      <div className="flex flex-col md:flex-row justify-between mt-4">
        <button
          type="button"
          className="bg-neutral-800 hover:bg-neutral-700 px-2 m-1 text-white font-semibold text-sm py-2 rounded-lg flex items-center justify-center"
          onClick={handleDeleteBooking}
        >
          <FaTrash className="mr-2" />
          Eliminar
        </button>
        {formData.status_booking >= 1 && (
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-400 w-3/6 m-1 text-white font-semibold text-sm py-2 rounded-lg"
          >
            Atualizar
          </button>
        )}
        {formData.status_booking == 0 && (
          <>
            <div
              type="submit"
              className="bg-green-500 hover:bg-green-400 w-3/6 m-1 text-white font-semibold text-center text-sm py-2 rounded-lg"
              onClick={handleConfirm}
            >
              Confirmar
            </div>
            <div
              type="submit"
              className="bg-red-500 hover:bg-red-400 w-3/6 m-1 text-white font-semibold text-center text-sm py-2 rounded-lg"
              onClick={handleReject}
            >
              Rechazar
            </div>
            <ConfirmationModal
              isOpen={isConfirmationModalOpen}
              message={confirmationMessage}
              onConfirm={confirmAction}
              onClose={closeConfirmationModal}
            />
            {isConfirmationModalOpen && (
              <div className="fixed inset-0 bg-black opacity-50" onClick={closeConfirmationModal}></div>
            )}
          </>
        )}
</div>



    </form>
  );
};

export { BookingEdit };
