import React, { useRef, useState, useEffect } from "react";
import { CgColorPicker } from "react-icons/cg";

const EditColorButton = (props) => {
  const colorRef = useRef(null);
  const [selectedColor, setSelectedColor] = useState("#000000");

  const { registerForm, handleUpdateColorUI, setButtonColor, buttonColor, setFormDirty, formDirty } = props;

  const handleUpdateColor = handleUpdateColorUI ? handleUpdateColorUI : () => {};

  useEffect(() => {
    if (buttonColor !== undefined) {
      setSelectedColor(buttonColor);
    }
  }, [buttonColor]);

  const handleColorPicker = () => {
    if (colorRef.current) {
      colorRef.current.click();
    }
  };

  const handlePresetColor = (color) => {
    if (color !== selectedColor) {
      setFormDirty(true);
    }
    handleUpdateColor(undefined, color);
    setSelectedColor(color); 
    setButtonColor(color);
  };

  const formConfig = registerForm
    ? registerForm("color", {
        onChange: (event) => {
          handleUpdateColor(event);
          setSelectedColor(event.target.value);
          setButtonColor(event.target.value);
          if (event.target.value !== selectedColor) {
            setFormDirty(true);
          }
        },
      })
    : { ref: () => {}, onChange: (event) => {
        setSelectedColor(event.target.value);
        setButtonColor(event.target.value); 
        if (event.target.value !== selectedColor) {
          setFormDirty(true);
        }
    }};

  const { ref, ...rest } = formConfig;

  return (
    <>
      <div className="flex flex-wrap items-center gap-4 sm:gap-6">
        <div
          onClick={handleColorPicker}
          className="p-2 cursor-pointer rounded-full w-fit bg-stone-200"
        >
          <CgColorPicker size={16} />
        </div>
        <input
          id="color"
          name="color"
          value={selectedColor}
          onChange={(event) => {
            setSelectedColor(event.target.value);
            setButtonColor(event.target.value);
          }}
          className="hidden"
          ref={colorRef}
          type="color"
        />
        <div
          onClick={() => handlePresetColor("#EF4444")}
          className="h-6 w-6 rounded-full cursor-pointer bg-red-500"
        ></div>
        <div
          onClick={() => handlePresetColor("#F97316")}
          className="h-6 w-6 rounded-full cursor-pointer bg-orange-500"
        ></div>
        <div
          onClick={() => handlePresetColor("#FACC15")}
          className="h-6 w-6 rounded-full cursor-pointer bg-yellow-400"
        ></div>
        <div
          onClick={() => handlePresetColor("#2563EB")}
          className="h-6 w-6 rounded-full cursor-pointer bg-blue-600"
        ></div>
        <div
          onClick={() => handlePresetColor("#16A34A")}
          className="h-6 w-6 rounded-full cursor-pointer bg-green-600"
        ></div>
        <div
          onClick={() => handlePresetColor("#A855F7")}
          className="h-6 w-6 rounded-full cursor-pointer bg-purple-500"
        ></div>
      </div>

      <div className="flex flex-wrap items-center gap-4 sm:gap-6 mt-3 justify-center">
        <div
          className="rounded-full px-2 py-2 text-white font-semibold text-center shadow-xl min-w-[250px]"
          style={{ backgroundColor: selectedColor }}
        >
          Ejemplo
        </div>
      </div>
    </>
  );
};

export default EditColorButton;
