import { IoIosArrowBack } from "react-icons/io";

export function PersonificationMenuBar(props) {
  const { stopPersonification, userPersonified } = props;
  function handlePopUser() {
    stopPersonification();
  }

  function lastUserPersonified() {
    return userPersonified.email;
  }
  return (
    <>
      {userPersonified? (
        <div className="px-2 py-2 w-full bg-stone-950 text-stone-200">
          <div
            onClick={handlePopUser}
            className="flex items-center cursor-pointer"
          >
            <IoIosArrowBack size={20} />
            <button className="ml-1">
            Voltar - Personificação de "{lastUserPersonified()}"
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
