import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaLink } from 'react-icons/fa';

const DiscountDetails = ({ discount, onFieldChange, onSave }) => {
  const [editableFields, setEditableFields] = useState({
    discount_type: discount.discount_type,
    discount_rate: discount.discount_rate,
    initial_date: discount.initial_date,
    final_date: discount.final_date,
    status: discount.status,
    customer_user: discount.customer_user,
    verification_code: discount.verification_code,
  });

  const copyToClipboard = () => {
    const textToCopy = `https://www.soueu.com.br/buy?cupon=${discount.verification_code}`;

    navigator.clipboard.writeText(textToCopy).then(
      () => {
        toast.success("Link copiado", {
          position: "top-center",
          bodyClassName: "text-sm",
          autoClose: 1500,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored", 
        });
      },
      (error) => {
        console.error('Erro de cópia', error);
      }
    );
  };

    const [hasChanges, setHasChanges] = useState(false);
    
  useEffect(() => {
    // Compara los campos editables con el estado inicial
    const initialFields = {
      discount_type: discount.discount_type,
      discount_rate: discount.discount_rate,
      initial_date: discount.initial_date,
      final_date: discount.final_date,
      status: discount.status,
      verification_code: discount.verification_code,
    };

    const changesDetected = Object.keys(editableFields).some(
      (key) => editableFields[key] !== initialFields[key]
    );
    
    
    setHasChanges(changesDetected);
  }, [discount, editableFields]);
 

    const handleFieldChange = (fieldName, value) => {
      setEditableFields((prevFields) => ({
        ...prevFields,
        [fieldName]: value,
      }));
  
      // Llama a la función de actualización en el componente principal
      onFieldChange(fieldName, value);
    };

    const handleSave = () => {
      const allEditableFields = {
      ...editableFields,
      social_media: discount.social_media,
    };

    // Llama a la función de guardado en el componente principal
    onSave(allEditableFields);
    };

  return (
    <tr>
    <td colSpan="9">
      {/* Contenido colapsable */}
      <div className="p-4 bg-gray-100">
        {/* Contenido a mostrar */}
        <div className="border rounded p-4 mb-4">
          <label className="block font-bold mb-2">Cupom de desconto Nº{discount.id}</label>

          <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
      <label className="block font-bold mb-1">Utilizador</label>
      <input
        type="text"
        className="border rounded px-2 py-1 w-full"
        value={editableFields.customer_user}
        onChange={(e) => handleFieldChange('customer_user', e.target.value)}
      />
    </div>
            <div>
              <label className="block font-bold mb-1">Rede Social</label>
              <p>{discount.social_media}</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
              <label className="block font-bold mb-1">Tipo de Desconto</label>
              <select
                className="border rounded px-2 py-1 w-full"
                value={editableFields.discount_type}
                onChange={(e) => handleFieldChange('discount_type', e.target.value)}
              >
                <option value="price">(Moeda) R$</option>
                <option value="percentage">(Percentagem) %</option>
              </select>
            </div>
            <div>
              <label className="block font-bold mb-1">Valor do Desconto Nº</label>
              <input
                type="text"
                className="border rounded px-2 py-1 w-full"
                value={editableFields.discount_rate}
                onChange={(e) => {
                  // Asegúrate de que solo se ingresen valores decimales
                  const decimalRegex = /^\d*\.?\d*$/;
                  if (decimalRegex.test(e.target.value) || e.target.value === '') {
                    handleFieldChange('discount_rate', e.target.value);
                  }
                }}
                placeholder="Insira um valor decimal"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block font-bold mb-1">Data de Início</label>
              <input
                type="date"
                className="border rounded px-2 py-1 w-full"
                value={editableFields.initial_date}
                onChange={(e) => handleFieldChange('initial_date', e.target.value)}
              />
            </div>
            <div>
              <label className="block font-bold mb-1">Data Final</label>
              <input
                type="date"
                className="border rounded px-2 py-1 w-full"
                value={editableFields.final_date}
                onChange={(e) => handleFieldChange('final_date', e.target.value)}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
  <div>
    <label className="block font-bold mb-1">Estado</label>
    <select
      className="border rounded px-2 py-1 w-full"
      value={editableFields.status}
      onChange={(e) => handleFieldChange('status', e.target.value === 'true')}
    >
      <option value={true}>Habilitado</option>
      <option value={false}>Desabilitado</option>
    </select>
  </div>
  <div>
    <label className="block font-bold mb-1">Código de Verificação</label>
    <div className="flex">
 {/*      <input
        type="text"
        className="border rounded px-2 py-1 w-full mr-2"
        value={editableFields.verification_code}
        onChange={(e) => handleFieldChange('verification_code', e.target.value)}
        
      /> */}
      <input
  type="text"
  className="border rounded px-2 py-1 w-full mr-2"
  value={editableFields.verification_code}
  onInput={(e) => handleFieldChange('verification_code', e.target.value.toUpperCase())}
/>
      <button
        className="bg-purple-500 text-white text-center px-4 py-4 rounded cursor-pointer hover:bg-purple-400"
        onClick={copyToClipboard}
        
      >
        <FaLink className="" />
      </button>
    </div>
  </div>
</div>

          <div className="flex justify-between">
          <div className=" mt-4">
              {/* Botón para copiar al portapapeles */}
              <button
                className="bg-purple-500 text-white px-4 py-2 rounded-xl cursor-pointer hover:bg-purple-400"
                onClick={copyToClipboard}
              >
                {discount.verification_code}
              </button>
            </div>
              <button
                className={`bg-blue-500 text-white font-semibold px-4 py-1 rounded-lg ${
                  hasChanges ? 'cursor-pointer hover:bg-blue-600' : 'cursor-not-allowed'
                }`}
                onClick={hasChanges ? handleSave : null}
                disabled={!hasChanges}
              >
               Salvar
              </button>
            </div>

        </div>
      </div>
    </td>
  </tr>
);
};

export  {DiscountDetails};