import SoyYoName from "../../../assets/landing-page/brand/soyyo-name.png";

import SoyYoLogo from "../../../assets/landing-page/brand/soyyo-logo.png";

import { HiOutlineMenu } from "react-icons/hi";
import { useEffect, useState } from "react";

import "../Components/LandingPageStyles.css";
import { scrollToById } from "../Services/Utils/scrollToById";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTER_INITIAL, ROUTER_LANDING_PAGE } from "../../../config/Constant";

export function Navbar(props) {
  const [openMenu, setOpenMenu] = useState(false);
  let [fixedPosition, setFixedPosition] = useState(false);

  const { forceFixed, user } = props;
  const location = useLocation();
  const navigate = useNavigate();

  fixedPosition = forceFixed ?? fixedPosition;

  function toggleMenu() {
    setOpenMenu(!openMenu);
  }

  function handleScroll2(event) {
    if (event.target.scrollTop >= 600) {
      setFixedPosition(true);
      return;
    }
    setFixedPosition(false);
  }

  function handleClickLink(id) {
    return () => {
      if (location.pathname !== ROUTER_LANDING_PAGE) {
        navigate(ROUTER_LANDING_PAGE, {
          state: {
            to: id, // A donde hacer scroll
          },
        });
      }

      scrollToById(id)();
      if (window.innerWidth < 768) {
        toggleMenu();
      }
    };
  }

  useEffect(() => {
    document.body.addEventListener("scroll", handleScroll2);

    return () => {
      document.removeEventListener("scroll", handleScroll2);
    };
  }, []);

  const navbarclass3 = openMenu
    ? "z-10 top-0 h-screen md:static md:h-auto md:bg-transparent bg-stone-950 fixed w-full"
    : "";

  const navbarclass2 = openMenu ? "h-screen md:h-auto" : "";

  const navbarclass1 = fixedPosition
    ? `${navbarclass2} bg-stone-950 fixed z-10 top-0 w-full`
    : `${navbarclass3}`;
  return (
    <nav
      className={`${navbarclass1} pl-2 pt-2 pr-6 flex flex-col md:flex-row md:justify-between`}
    >
      <div className="flex w-full md:w-fit justify-between items-center">
        {fixedPosition ? (
          <Link to={ROUTER_LANDING_PAGE} className="flex px-2 py-2">
            <img src={SoyYoLogo} alt="SoyYo" className="h-10" />
            <img src={SoyYoName} alt="SoyYo" className="h-10" />
          </Link>
        ) : (
          <Link to={ROUTER_LANDING_PAGE}>
            <img src={SoyYoName} alt="SoyYo" className="h-16 lg:h-24" />
          </Link>
        )}
        <div onClick={toggleMenu} className="md:hidden">
          <HiOutlineMenu className="px-1 py-0.5 text-4xl box-content cursor-pointer rounded-md text-white hover:bg-stone-700" />
        </div>
      </div>

      <div
        className={`${
          openMenu
            ? "opacity-100"
            : "h-0 max-h-0 md:max-h-fit overflow-hidden opacity-0 "
        } md:h-auto md:opacity-100 md:flex-row md:text-base md:gap-8 transition-opacity delay-75 duration-300 flex flex-col items-center h-80  justify-around text-center text-xl font-sans text-white`}
      >
        <button
          onClick={handleClickLink("start")}
          className="strikethrough-hover "
        >
          Início
        </button>
        <button
          onClick={handleClickLink("benefits")}
          className="strikethrough-hover"
        >
          Benefícios
        </button>
        <button
          onClick={handleClickLink("products")}
          className="strikethrough-hover"
        >
          Produtos
        </button>
        <button
          onClick={handleClickLink("steps")}
          className="strikethrough-hover"
        >
          Obtenha seu cartão
        </button>
        {/* <button
          onClick={handleClickLink("clients")}
          className="strikethrough-hover"
        >
          Nuestros Clientes
        </button> */}
        <Link
          to={ROUTER_INITIAL}
          className="px-6 py-3 md:px-4 md:py-1 border rounded-md transition-colors hover:border-stone-50 hover:text-stone-200 hover:bg-stone-800 bg-stone-50 text-black"
        >
          {user?.username ? user?.username : "Faça Login"}
        </Link>
      </div>
    </nav>
  );
}
