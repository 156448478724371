import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { FaPhone, FaComment, FaEnvelope } from "react-icons/fa";
import LoadingButton from "../../../../components/LoadingButton";
import infoIcon from "../../../../assets/images/social_media_icons/icons8-contact-96.png";
import { createClientContactConfig, updateClientContactConfig, getClientContactConfigDetails } from '../../../client_contact/Api/ClientContact';

const AddContactModal = (props) => {
  const token = useSelector((state) => state.login.token);
  const [buttons, setButtons] = useState({
    phone: true,
    email: true,
    comment: true,
  });
  const { setShowModal, hasContactCustomMedia, setHasContactCustomMedia, userId } = props;
  const titleType = "de contato";
  const imageSource = infoIcon;
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState, setValue } = useForm();
  const { onComplete, onSubmit } = props;
  const toggleButton = (button) => {
    setButtons({
      ...buttons,
      [button]: !buttons[button],
    });
  };

  function closeModal() {
    setShowModal(false);
  }

  function formHasBeenEdited() {
    return formState.isDirty;
  }

  async function handleAddCustomSocialMedia(data) {
    const originalData = {
      title: "Contato",
      url: "contato",
      is_visible: true,
      type: "contact",
      customer_user: data.user_id,
      phone_enabled: buttons.phone,
      email_enabled: buttons.email, 
      comment_enabled: buttons.comment,
      ...data,
    };

    setIsLoading(true);

    let modifiedData; // Definir modifiedData fuera del bloque try

    try {
      const customSocialMediaResponse = await onSubmit(originalData, token);
      onComplete(customSocialMediaResponse.data);
      setHasContactCustomMedia(true);
      modifiedData = {
        message: originalData.message,
        phone_enabled: buttons.phone,
        email_enabled: buttons.email,
        comment_enabled: buttons.comment,
        customer_user: originalData.user_id,
      };

      // Intentar crear un nuevo clientcontactconfig
      const contactConfigResponse = await createClientContactConfig(modifiedData, token);
    } catch (error) {
      console.error("Error :", error);
      if (
        (error.response && error.response.status === 400 && error.response.data.detail === "Valor duplicado") ||
        (error.response && error.response.status === 400 && error.response.data.customer_user && error.response.data.customer_user[0] === "Este campo debe ser único.")
      ) {
        // Si se produce un error de clave duplicada, interpretarlo como una señal para actualizar en lugar de crear
        try {
          // Usar modifiedData definido fuera del bloque try
          const updateResponse = await updateClientContactConfig(originalData.user_id, modifiedData, token);
        } catch (updateError) {
          console.error("Error :", updateError);
        }
      } else {
        console.error("Error");
      }
    } finally {
      setIsLoading(false);
      closeModal();
    }
  }

  useEffect(() => {
    const fetchClientContactConfigDetails = async () => {
      try {
        const details = await getClientContactConfigDetails(userId, token);

        // Rellenar el formulario con los datos obtenidos
        if (details) {
          setValue("message", details.message);
          setButtons({
            phone: details.phone_enabled,
            email: details.email_enabled,
            comment: details.comment_enabled,
          });
        }
      } catch (error) {
        console.error("Error fetching client contact config details:", error);
      }
    };

    fetchClientContactConfigDetails();
  }, [userId, token, setValue]);

  return (
    <>
      <p className="font-medium"> {`Enlace ${titleType}`} </p>
      <div className="mt-4 flex items-center">
        <img
          className="max-w-[4rem] rounded-md cursor-pointer"
          alt={`Icono ${titleType}`}
          src={imageSource}
        />
        <a className="ml-4">Contato</a>
      </div>
      <span className="text-neutral-500">Ativar notificação rápida</span>
      <form
        className="mt-4 flex flex-col text-sm"
        action="POST"
        onSubmit={handleSubmit(handleAddCustomSocialMedia)}
      >
        <div>
          <label htmlFor="message" className="block font-medium">
            Personalizar mensagem
          </label>
          <textarea
            id="message"
            name="message"
            placeholder="compartilhar dados"
            className="block w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            {...register("message")}
          />
        </div>

        <div className=" mb-2 mt-2">
          <label className=" font-medium ">
            Solicite dados adicionais:
          </label>
          <span className="text-neutral-500 mb-1">
            Selecione os dados que deseja receber do usuário que deseja entrar em contato com você
          </span>
          <div className=" flex justify-center gap-4">
            <div>
              <div
                className={`flex items-center justify-center cursor-pointer rounded-full w-16 h-16 text-2xl text-white focus:outline-none ${buttons.phone ? "bg-blue-500 hover:bg-blue-400" : "bg-red-500 hover:bg-red-400 "}`}
                onClick={() => toggleButton("phone")}
              >
                <FaPhone />
              </div>
              <span className={`text-sm font-medium text-center ${buttons.phone ? "text-blue-500" : "text-red-500"}`}>
                {buttons.phone ? "Si" : "Ñao"}
              </span>
            </div>

            <div>
              <div
                className={`flex items-center justify-center cursor-pointer rounded-full w-16 h-16 text-2xl text-white focus:outline-none ${buttons.email ? "bg-blue-500 hover:bg-blue-400" : "bg-red-500 hover:bg-red-400 "}`}
                onClick={() => toggleButton("email")}
              >
                <FaEnvelope />
              </div>
              <span className={`text-sm font-medium text-center ${buttons.email ? "text-blue-500" : "text-red-500"}`}>
                {buttons.email ? "Si" : "Ñao"}
              </span>
            </div>

            <div>
              <div
                className={`flex items-center justify-center cursor-pointer rounded-full w-16 h-16 text-2xl text-white focus:outline-none ${buttons.comment ? "bg-blue-500 hover:bg-blue-400" : "bg-red-500 hover:bg-red-400 "}`}
                onClick={() => toggleButton("comment")}
              >
                <FaComment />
              </div>
              <span className={`text-sm font-medium text-center ${buttons.comment ? "text-blue-500" : "text-red-500"}`}>
                {buttons.comment ? "Si" : "Ñao"}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-4 flex items-center justify-end">
          <div className="flex sm:flex-row gap-2">
            <button
              type="button"
              onClick={closeModal}
              className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
            >
              Cancelar
            </button>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              className="px-6 py-1 font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
              disabled={!formHasBeenEdited()}
            >
              adicionar
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddContactModal;
