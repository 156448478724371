import React, { useState, useEffect } from 'react';
import TimeRangeSlider from './TimeRangeSlider';
import {getBookingSettings, updateBookingSettings,useUser} from '../../Api/Booking';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CountryCodeOptions from '../../../../utils/CountryCodeOptions';

const BookingConfig = (props) => {
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);
  const user = useUser(token) || { id: null };
  const [max_personas, setMaxPersonas] = useState('');
  const [time_bet_booking, setTimeBetBooking] = useState('');
  const [hora_inicio, setHoraInicio] = useState(0);
  const [hora_fin, setHoraFin] = useState(0);
  const [hora_inicio_tarde, setHoraInicioTarde] = useState(0);
  const [hora_fin_tarde, setHoraFinTarde] = useState(0);
  const [hora_inicio_noche, setHoraInicioNoche] = useState(0);
  const [hora_fin_noche, setHoraFinNoche] = useState(0);
  const [status_conf, setStatusConf] = useState('');
  const [kids, setKids] = useState(false);
  const [teen, setTeen] = useState(false);
  const [phone, setPhone] = useState(false);
  const [email, setEmail] = useState(false);
  const [title, setTitle] = useState("");
  const [customTitle, setCustomTitle] = useState('');
  const [max_reservas, setMaxReservas] = useState(false);
  const [holiday, setHoliday] = useState(['', '', '', '', '', '', '']);
  const [selectedSection, setSelectedSection] = useState(null);
  const [statusConfValue, setStatusConfValue]=useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState("55"); // El valor predeterminado podría ser el código del país que desees
  const handleCountryCodeChange = (e) => {
  setSelectedCountryCode(e.target.value);
  };
  const {id}=props;

  const [formChanged, setFormChanged] = useState(false);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setFormChanged(true);
  };

  const handleCustomTitleChange = (e) => {
    setCustomTitle(e.target.value);
    setFormChanged(true);
  };

  const handleDayClick = (index) => {
    const updatedHoliday = [...holiday];
    updatedHoliday[index] = !updatedHoliday[index];
    setHoliday(updatedHoliday);
    setFormChanged(true);
  };

  const formatHour = (minutes) => {
    if (isNaN(minutes)) {
      return '00:00';
    }
  
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMins = mins < 10 ? `0${mins}` : `${mins}`;
    return `${formattedHours}:${formattedMins}`;
  };

  const handleSectionSelect = (section) => {
    setSelectedSection(section);
  };

  const handleCheckboxChange = (value) => {
    setStatusConf(value);
    let numericValue;
    
    if (value === 0) {
      numericValue = 0; // Desactivado
    } else if (value === 1) {
      numericValue = 1; // Activado
      setMaxReservas(1);
    } else if (value === 2) {
      numericValue = 2; // En Grupo
    }
    setStatusConfValue(numericValue);
    setFormChanged(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      hora_inicio === '' || hora_fin === '' ||
      hora_inicio_tarde === '' || hora_fin_tarde === '' ||
      hora_inicio_noche === '' || hora_fin_noche === ''
    ) {
      // Mostrar un toast indicando que faltan fechas
      toast.error("Falta definir horários do dia", {
        position: "top-center",
        bodyClassName: "text-sm",
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
      return;
    }

    try {
      const phoneNumberWithCode = phone ? phone : `${selectedCountryCode}${phone}`;
      let statusConfValue;
      if (!status_conf) {
        statusConfValue = 0; // Desactivado
      } else if (status_conf === 'individual') {
        statusConfValue = 1; // Activado
         setMaxReservas(1);
      } else if (status_conf === 'grupo') {
        statusConfValue = 2; // En Grupo
      }
  
      const updatedSettings = {
        max_personas,
        time_bet_booking,
        status_conf: status_conf,
        kids,
        teen,
        hora_inicio:formatHour(hora_inicio),
        hora_fin:formatHour(hora_fin),
        hora_inicio_tarde:formatHour(hora_inicio_tarde),
        hora_fin_tarde:formatHour(hora_fin_tarde),
        hora_inicio_noche:formatHour(hora_inicio_noche),
        hora_fin_noche:formatHour(hora_fin_noche),
        holiday: JSON.stringify(holiday),
        customer_user: id,
        phone: phoneNumberWithCode,
        btn:title,
        email,
        max_reservas,
      };
      
      if (!token) {
        return;
      }
      await updateBookingSettings(updatedSettings, token);
      setFormChanged(false);
      toast.success("Horario de reservas Registrado!", {
        position: "top-center",
        bodyClassName: "text-sm",
        autoClose: 1000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
      
    } catch (error) {
      toast.error("error de guardado", {
        position: "top-center",
        bodyClassName: "text-sm",
        autoClose: 1000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });
    }
  };
 
  useEffect(() => {
    const fetchBookingSettings = async () => {
      try {
        if (!token || !user.id) {
          return;
        }
        const settingsResponse = await getBookingSettings(id,token);
        const userSettings = settingsResponse.data;
        setMaxPersonas(userSettings.max_personas);
        setTimeBetBooking(userSettings.time_bet_booking);
        setStatusConf(userSettings.status_conf);
        setKids(userSettings.kids);
        setTeen(userSettings.teen);
        setHoraInicio(userSettings.hora_inicio);
        setHoraFin(userSettings.hora_fin);
        setHoraInicioTarde(userSettings.hora_inicio_tarde);
        setHoraFinTarde(userSettings.hora_fin_tarde);
        setHoraInicioNoche(userSettings.hora_inicio_noche);
        setHoraFinNoche(userSettings.hora_fin_noche);
        setPhone(userSettings.phone);
        setEmail(userSettings.email);
        setTitle(userSettings.btn);

        setMaxReservas(userSettings.max_reservas);

        const parsedHoliday = JSON.parse(userSettings.holiday);
        if (parsedHoliday.length === 7) {
          setHoliday(parsedHoliday);
        }
        setStatusConfValue(userSettings.status_conf);

      if (userSettings.hora_inicio && userSettings.hora_fin) {
        setHoraInicio(getMinutes(userSettings.hora_inicio));
        setHoraFin(getMinutes(userSettings.hora_fin));

      } else {
        // Set default values if hora_inicio or hora_fin is not available
        setHoraInicio(getMinutes("00:00:00"));
        setHoraFin(getMinutes("00:00:00"));
      } 

      if (userSettings.hora_inicio_tarde && userSettings.hora_fin_tarde) {
        setHoraInicioTarde(getMinutes(userSettings.hora_inicio_tarde));
        setHoraFinTarde(getMinutes(userSettings.hora_fin_tarde));
      } else {
        // Set default values if hora_inicio_tarde or hora_fin_tarde is not available
        setHoraInicioTarde(getMinutes("00:00"));
        setHoraFinTarde(getMinutes("00:00"));
      }

      if (userSettings.hora_inicio_noche && userSettings.hora_fin_noche) {
        setHoraInicioNoche(getMinutes(userSettings.hora_inicio_noche));
        setHoraFinNoche(getMinutes(userSettings.hora_fin_noche));
      }  else {
        // Set default values if hora_inicio_noche or hora_fin_noche is not available
        setHoraInicioNoche(getMinutes("00:00"));
        setHoraFinNoche(getMinutes("00:00"));
      }
      } catch (error) {
        
        //console.error('Error al obtener la configuración de reserva:', error);
        // Puedes manejar el error de acuerdo a tus necesidades, mostrar un mensaje, etc.
      }
    };

    const getMinutes = (timeString) => {
      const [hours, minutes, seconds] = timeString.split(':');
      return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
    };

    fetchBookingSettings();
  }, [token, user.id, selectedSection]);
  
  return (
    <>
      <div className="px-8 flex justify-between">
        <h2 className="font-medium text-lg min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
        Ajustes
        </h2>
      </div>
    

    <div className=" mt-2 flex flex-col items-center"> 


    
      <form onSubmit={handleSubmit} className=" mt-2  rounded text-sm w-11/12">

      <div className="mb-4 rounded-lg bg-white p-4 shadow-md">
        <label className="block text-xs mb-1 font-semibold text-neutral-600" htmlFor="titulo">
          Título:
        </label>
        <input
          id="titulo"
          type="text"
          value={title}  // Vincula el valor al estado
          onChange={(e) => {
            setTitle(e.target.value);
            setFormChanged(true);
          }}
          className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
          placeholder="Introduce el título"
        />
      </div>


        <div className="mb-4 rounded-lg bg-white p-4 shadow-md">
          <p className="text-xs mb-3 font-semibold text-neutral-600">Tipo de Reservas (seleccionar)</p>
          <div className="flex flex-col">
            <div className="mb-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={status_conf === 0}  // Desactivado
                  onChange={() => handleCheckboxChange(0)}
                  className="mr-2 appearance-none h-5 w-5 border border-gray-300 rounded-md checked:bg-red-500 "
                />
                <span className="text-base text-neutral-600">Deshabilitar</span>
              </label>
              <p className='text-xs font-medium text-neutral-500'>deshabilitar reservas</p>
            </div>

            <div className="mb-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={status_conf === 1}  // Activado
                  onChange={() => handleCheckboxChange(1)}
                  className="mr-2 appearance-none h-5 w-5 border border-gray-300 rounded-md checked:bg-green-500 "
                />
                <span className="text-base text-neutral-600">Individual</span>
              </label>
              <p className='text-xs font-medium text-neutral-500'>una reserva por fecha y hora</p>
            </div>

            <div>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={status_conf === 2}  // Activado
                  onChange={() => handleCheckboxChange(2)}
                  className="mr-2 appearance-none h-5 w-5 border border-gray-300 rounded-md checked:bg-purple-500 "
                />
                <span className="text-base text-neutral-600">Grupal</span>
              </label>
              <p className='text-xs font-medium text-neutral-500'>varias reservas para una fecha y hora</p>
            </div>
          </div>
        </div>

        <div className=" mb-4 rounded-lg bg-white p-4 shadow-md">
          {/* Campo de teléfono */}
          <label className=" mr-4 flex-1">
            <p className='text-xs mb-3 font-semibold text-neutral-600'>Teléfono:</p>
            <div className="items-center">
              {/* Campo de zona horaria */}
              <select
                className="border-2 mr-1 border-neutral-300 rounded-lg text-xs py-1 bg-neutral-200"
                name="countryCode"
                value={selectedCountryCode}
                onChange={(e) => {
                  handleCountryCodeChange(e.target.value);
                  setFormChanged(true);
                }}
              >
                <CountryCodeOptions />
              </select>

              {/* Campo de número de teléfono */}
              <input
                className="p-2 border-2 rounded-lg w-2/4  text-base bg-neutral-100"
                type="tel"
                name="phone"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setFormChanged(true);
                }}
              />
            </div>
          </label>

          {/* Campo de correo electrónico */}
          <label className="block">
            <p className='text-xs mb-3 font-semibold text-neutral-600'>Email:</p>       
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setFormChanged(true);
              }}
              className="border-2 p-2 rounded-lg  w-2/4 text-base bg-neutral-100"
            />
          </label>
        </div>


        <div className="flex mb-4  rounded-lg bg-white p-4 shadow-md ">
          <label className="block mr-3  ">
            <p className='text-xs mb-3 font-semibold text-neutral-600'>Nº Personas por Reserva:</p>       
            <input
              type="number"
              value={max_personas}
              onChange={(e) => {
                setMaxPersonas(e.target.value);
                setFormChanged(true);
              }}
              className="w-full p-2 border-2 border-neutral-300 rounded text-xl bg-neutral-100  text-center "
            />
          </label>

          <label className="block ">
            <p className='text-xs mb-3 font-semibold text-neutral-600'>Tiempo estimado por reservas:</p>
            <input
              type="number"
              value={time_bet_booking}
              onChange={(e) => {
                setTimeBetBooking(e.target.value);
                setFormChanged(true);
              }}
              className="w-full p-2 border-2 border-neutral-300 rounded text-xl bg-neutral-100  text-center"
            />
            <p className='text-xs mb-3 font-semibold text-neutral-600'>(minutos)</p>
          </label>

          <label className= {`block ml-3 ${status_conf !== 2 ? 'hidden' : ''}`}>
            <p className='text-xs mb-3 font-semibold text-neutral-600'>Nº de Reservas simultáneas:</p>       
            <input
              type="number"
              value={max_reservas}
              onChange={(e) => {
                setMaxReservas(e.target.value);
                setFormChanged(true);
              }}
              className="w-full p-2 border rounded text-xl bg-neutral-200"
            />
          </label>
        </div>

        <label className="block mb-4  rounded-lg bg-white p-4 shadow-md">
          <p className='text-xs mb-3 font-semibold text-neutral-600'>Días Hábiles:</p>
          <div className="flex mt-2 overflow-x-auto text-center">
            {['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'].map((day, index) => (
              <div
                key={index}
                onClick={() => handleDayClick(index)}
                className={`flex-1 p-3 mr-2 text-white rounded ${holiday[index] ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'}`}
              >
                {day}
              </div>
            ))}
          </div>
        </label>

        <p className='text-xs font-semibold text-neutral-600 mt-2 mb-3'>Establecer Hora de Trabajo:</p>
        <div className="mb-4 flex flex-col items-left ">
          <label className='block mb-2'>
            <div className="flex flex-col mt-2">


            <div className="flex flex-col text-center rounded-lg bg-white p-4 shadow-md mb-4">
              <label className='text-sm text-neutral-700 font-semibold mt-3 mb-3'>Horario de la Mañana</label>
              <div className="w-full">
                <TimeRangeSlider
                  value={[hora_inicio, hora_fin]}
                  onChange={(value) => {
                    setHoraInicio(value[0]);
                    setHoraFin(value[1]);
                    setFormChanged(true);
                  }}
                  min={6 * 60}
                  max={11 * 60 + 59}
                  initialValues={[hora_inicio, hora_fin]}
                  sliderColor="#4ab4ed"
                />
              </div>
            </div>

        <div className="flex flex-col text-center rounded-lg bg-white p-4 shadow-md mb-4">
        <label className='text-sm text-neutral-700 text-center font-semibold mt-3 mb-3'>Horario de la Tarde</label>
                      <div className="w-full">
                      <TimeRangeSlider
                            value={[hora_inicio_tarde, hora_fin_tarde]}
                            onChange={(value) => {
                              setHoraInicioTarde(value[0]);
                              setHoraFinTarde(value[1]);
                              setFormChanged(true);
                            }}
                            min={12 * 60}
                            max={18 * 60 + 59}
                            sliderColor="#ed934a"
                            
                          />
                      </div>
                    </div>

        <div className="flex flex-col text-center rounded-lg bg-white p-4 shadow-md mb-4">
        <label className='text-sm text-neutral-700 text-center font-semibold mt-3 mb-3'>Horario de la Noche</label>
                      <div className="w-full">
                      <TimeRangeSlider
                            value={[hora_inicio_noche, hora_fin_noche]}
                            onChange={(value) => {
                              setHoraInicioNoche(value[0]);
                              setHoraFinNoche(value[1]);
                              setFormChanged(true);
                            }}
                            min={19 * 60}
                            max={23 * 60 +59}
                            sliderColor="#8052eb"
                          />
                      </div>
                    </div>


            </div>
          </label>
        </div>

        <p className='text-xs mb-3 font-semibold text-neutral-600'>Otros Ajustes:</p>
        <div className="flex mb-4  rounded-lg bg-white p-4 shadow-md">
          <div className="w-1/2 pr-2">
            <label className="block relative">
              <p className='text-xs font-semibold text-neutral-600 mb-2'>Niños:</p>
              <div
                className={`ml-2 relative rounded-full w-10 h-6 transition-all duration-300 ${kids ? 'bg-green-500' : 'bg-red-500'}`}
                onClick={() => {
                  setKids(!kids);
                  setFormChanged(true);
                }}
              >
                <div
                  className={`absolute left-1 top-1 w-4 h-4 rounded-full transition-all duration-300 ${kids ? 'bg-white transform translate-x-full' : 'bg-white'}`}
                />
              </div>
            </label>
          </div>
          <div className="w-1/2 pl-2">
            <label className="block relative">
              <p className='text-xs font-semibold text-neutral-600 mb-2'>Bebés:</p>
              <div
                className={`ml-2 relative rounded-full w-10 h-6 transition-all duration-300 ${teen ? 'bg-green-500' : 'bg-red-500'}`}
                onClick={() => {
                  setTeen(!teen);
                  setFormChanged(true);
                }}
              >
                <div
                  className={`absolute left-1 top-1 w-4 h-4 rounded-full transition-all duration-300 ${teen ? 'bg-white transform translate-x-full' : 'bg-white'}`}
                />
              </div>
            </label>
          </div>
        </div>
        
        <button 
          type="submit" 
          className={`w-3/6 p-2 py-3  bg-blue-500 text-white rounded hover:bg-blue-600 ${formChanged ? '' : 'cursor-not-allowed opacity-50'} mx-auto flex items-center justify-center mb-6`}
          disabled={!formChanged}>
          Guardar Cambios
        </button>

      </form>
    </div>
    </>
  );
};

export  {BookingConfig};

