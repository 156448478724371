import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/FirebaseUtils";
import {
  sendPasswordResetEmail,
  updateEmail,
} from "firebase/auth";

import {  ROUTER_INITIAL } from "../../config/Constant";

import "../../styles/verifyEmail.css";

import soyYoLogoNegro from "../../../src/assets/images/soy-yo-logo-negro.png";
import soyYoNameNegro from "../../../src/assets/images/soy-yo-nombre-negro.png";
import imageVerify from "../../../src/assets/images/background/image-reset.png";
import spark from "../../../src/assets/images/background/spark.png";
import { useForm } from "react-hook-form";
import LoadingButton from "../../components/LoadingButton";
import { AiOutlineArrowLeft } from "react-icons/ai";


const VerifyEmail = () => {
  const { register, handleSubmit } = useForm();
  const [, setEmail] = useState("");
  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged(function (userFirebase) {
      if (userFirebase) {
        setEmail(userFirebase.email);
        setUser(userFirebase);
      }
    });
  });

  function handleActivateAccount(data) {
    // TODO: Corregir
    setLoading(true);
    updateEmail(user, data.email)
      .then((response) => {
        sendPasswordResetEmail(auth, data.email)
          .then((response) => {
            window.alert(
              "Um link foi enviado para ativar sua conta e alterar sua senha."
            );
            return history(ROUTER_INITIAL);
          })
          .catch((error) => {
            console.error(error);
            return history(ROUTER_INITIAL);
          });
      })
      .catch((error) => {
        console.error(error);
        return history(ROUTER_INITIAL);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleGoBack() {
    return history(ROUTER_INITIAL);
  }
  return (
    <div className="font-montserrat flex w-full h-full bg-stone-950">
      <div className="md:w-[45%] min-w-[22rem] md:max-w-[30rem] relative rounded-none md:rounded-r-[3rem] w-full h-full flex flex-col items-center bg-stone-50">
        <button onClick={handleGoBack}>
          <AiOutlineArrowLeft className="p-2 left-2 top-2 text-xl absolute cursor-pointer box-content rounded-full hover:bg-stone-800/10" />
        </button>

        <div className="p-4 mt-8 opacity-80 rounded-3xl flex flex-row">
          <img className="h-9" src={soyYoLogoNegro} alt="SoyYo logo"></img>
          <img className="h-9" src={soyYoNameNegro} alt="SoyYo marca"></img>
        </div>
        <h1 className="mt-[5vh] font-manrope text-2xl transition-font-size font-medium text-stone-700">
          Activación de cuenta
        </h1>
        <p className="mt-3">
          Para:
          <strong className="font-medium"> {auth.currentUser.email} </strong>
        </p>
        <p className="mt-8 px-6 w-full text-center">
          Ingresa un nuevo correo electrónico para poder utilizar tu cuenta.
        </p>
        <div className="mt-10 max-w-[26rem] flex flex-col w-full">
          <form
            onSubmit={handleSubmit(handleActivateAccount)}
            className="px-6 w-full flex flex-col items-center"
          >
            <div className="mb-8 w-full text-input-group relative">
              <input
                id="email"
                className="login-input px-4 py-3 text-sm font-medium transition-font-size w-full rounded-lg text-input-login force-label-up"
                type="text"
                {...register("email")}
                required
              />
              <label
                htmlFor="email"
                className="pointer-events-none transition-all"
              >
                Correo electrónico
              </label>
            </div>
            <div className="max-w-[24rem] flex flex-col w-full">
              <LoadingButton
                isLoading={loading}
                className="rounded-md w-fit mx-auto px-4 py-1 text-stone-50 bg-stone-950"
              >
                Cambiar correo
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>

      <div className="h-full hidden overflow-hidden relative md:flex w-full px-2 justify-center items-center">
        <img alt="" className="absolute left-3/4 mb-8 w-8" src={spark} />
        <img
          alt=""
          className="absolute left-1/4 top-3/4 mb-8 w-8"
          src={spark}
        />
        <img
          alt=""
          className="absolute left-1/2 top-1/4 mb-8 w-8"
          src={spark}
        />

        <div className="skew translate-card absolute max-h-[40rem]  bg-white h-full aspect-[3/8] rounded-xl">
          <div className="relative w-full h-full">
            <div className="absolute max-h-[40rem] left-8 top-8 border-2 border-white h-full aspect-[3/8] rounded-xl"></div>
          </div>
        </div>

        <img
          alt=""
          className="absolute mb-16 max-w-[45vw] max-h-[35rem]"
          src={imageVerify}
        />
      </div>
    </div>
  );
};

export default VerifyEmail;
