export function StepsCard(props) {
  const { image, index, description } = props;

  function renderImage() {
    return <img alt="" src={image} className="h-32 sm:h-52 rounded-xl shadow-lg" />;
  }

  return (
    <div
      className={`h-full flex flex-col items-center`}
    >
      {image && renderImage()}
      <strong className="mt-4 font-semibold text-3xl">{index}</strong>
      <p className="max-w-sm sm:text-lg font-medium text-center"> {description} </p>
    </div>
  );
}
