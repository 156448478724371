import React, { useState } from "react";
import AddSocialMediaModal from "./AddSocialMediaModal";

import { IoMdAddCircle } from "react-icons/io";

const AddCustomLinkButton = (props) => {
  const { addSocialMedia, onSubmit } = props;
  const [openEditorModal, setOpenEditorModal] = useState(false);

  function openEditor() {
    setOpenEditorModal(true);
  }

  return (
    <>
    <div className="w-full flex items-center">

      <button 
      aria-label="criar link personalizado"
      className="mt-4 mx-auto" type="button" onClick={openEditor}>
        <IoMdAddCircle
          className="text-stone-800"
          size={32}
          />
      </button>
          </div>
      {openEditorModal && (
        <AddSocialMediaModal
          setShowModal={setOpenEditorModal}
          onComplete={addSocialMedia}
          titlePlaceholder=""
          addSocialMedia={addSocialMedia}
          onSubmit={onSubmit}
          titleType="personalizado"
        />
      )}
    </>
  );
};
export default AddCustomLinkButton;
