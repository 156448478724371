import virtualCard from "../../../assets/landing-page/decoration/plan-virtual2.png";
import basicCard from "../../../assets/landing-page/decoration/plan-simple-modelo.png";
import customCard from "../../../assets/landing-page/decoration/plan-medio-modelo.png";
import fullDesignCard from "../../../assets/landing-page/decoration/plan-avanzado-modelo.png";

import comboFullDesignCard from "../../../assets/landing-page/decoration/propuesta/plan-fulldesign-modelo.png";
import comboNFCCard from "../../../assets/landing-page/decoration/propuesta/plan-nfc-modelo.png";

export const productCodes = {
    DIGITAL: 5,
    BASIC: 2,
    CUSTOM: 3,
    FULL_DESIGN: 4,
    COMBO_FULL_DESIGN: 5,
    COMBO_NFC: 6,
}

export const defaultProducts = {
  /* [productCodes.DIGITAL]: {
    id: productCodes.DIGITAL,
    title: "Cartão Digital",
    image: virtualCard,
    description: "Desfrute de um cartão digital",
    price: 215,
  },
  [productCodes.BASIC]: {
    id: productCodes.BASIC,
    title: "Cartão Básico",
    image: basicCard,
    description: "Conecte-se com nossa tecnologia",
    price: 185,
  }, */
  [productCodes.CUSTOM]: {
    id: productCodes.CUSTOM,
    title: "Cartão Customizado",
    image: customCard,
    description: "Sua marca, nossa tecnologia",
    price: 89.99,
  },
  [productCodes.FULL_DESIGN]: {
    id: productCodes.FULL_DESIGN,
    title: "Cartão Design Completo",
    image: fullDesignCard,
    description: "Controle total sobre seu cartão e sua marca",
    price: 129.99,
  },
 
};
